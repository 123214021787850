// /* @flow */
import _ from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import categoryActions from '../actions/categories'
import * as categoryThunks from '../actions/categoriesThunks'
import * as inventoryThunks from '../actions/inventoryThunks'
import * as mainActions from '../actions/main'
import * as actionTypes from '../actionTypes'
import FooterBar from '../components/FooterBar'
import { viewTypes, modalTypes } from '../constants'
import EditCategoryForm from './EditCategoryForm'
import InventoryTypesList from './InventoryTypesList'
/* import type {
  Action,
  CategoryAction,
  SetViewMainAction,
  DisplayModalMainAction,
} from '../flowTypes/actions' */
/* import type { ThunkAction } from '../flowTypes/redux' */

/* type Props = {
  categoryId: string,
  cancelCategory: () => CategoryAction,
  validateCategory: ({ [string]: boolean }) => CategoryAction,
  saveCategory: () => ThunkAction,
  displayModal: string => DisplayModalMainAction,
  setView: string => SetViewMainAction,
  addInventory: string => Action,
} */

class EditCategorySection extends React.PureComponent /* <Props> */ {
  categoryInputs = {}

  _validateCategory = () => {
    const recentFormErrors = _.reduce(
      this.categoryInputs,
      (accum, input, key) => (input.validate() ? accum : { ...accum, [key]: true }),
      {}
    )
    this.props.validateCategory(recentFormErrors)
    return recentFormErrors
  }

  _saveCategory = async () => {
    const saveCategoryResult = await this.props.saveCategory()
    const isSuccessfulSave =
      saveCategoryResult.type === actionTypes.CATEGORY_POST_SUCCESS || saveCategoryResult.type === actionTypes.CATEGORY_PUT_SUCCESS
    return isSuccessfulSave
  }

  handleSaveCategoryClick = () => {
    const formErrors = this._validateCategory()
    if (_.isEmpty(formErrors)) {
      const isSuccessfulSave = this._saveCategory()
      if (isSuccessfulSave) {
        this.props.setView(viewTypes.VIEW_CATEGORIES)
      }
    }
  }

  handleAddInventoryClick = async () => {
    if (this.props.categoryId) {
      this.props.addInventory(this.props.categoryId)
      this.props.displayModal(modalTypes.EDIT_INVENTORY)
    } else {
      const formErrors = this._validateCategory()
      if (_.isEmpty(formErrors)) {
        const isSuccessfulSave = await this._saveCategory()
        if (isSuccessfulSave) {
          this.props.addInventory(this.props.categoryId)
          this.props.displayModal(modalTypes.EDIT_INVENTORY)
        }
      }
    }
  }

  render() {
    return (
      <SectionWrapper>
        <ReactTooltip effect="solid" />
        <EditCategoryForm categoryInputs={this.categoryInputs} />
        <InventoryTypesList handleAddInventoryClick={this.handleAddInventoryClick} />
        <FooterBar onSaveClick={this.handleSaveCategoryClick} onCancelClick={this.props.cancelCategory} />
      </SectionWrapper>
    )
  }
}

const SectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px 20px 120px 20px;
`

const mapStateToProps = state => ({
  categoryId: state.categories.edit.id,
})

const mapDispatchToProps = {
  cancelCategory: categoryActions.cancelCategory,
  validateCategory: categoryActions.validateCategory,
  saveCategory: categoryThunks.saveCategory,
  setView: mainActions.setView,
  displayModal: mainActions.displayModal,
  addInventory: inventoryThunks.addInventory,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCategorySection)
