// /* @flow */
import * as React from 'react'
import styled from 'styled-components'
import { viewTypes } from '../constants'
/* import type { SetViewMainAction } from '../flowTypes/actions' */

/* type Props = {
  view: $Keys<typeof viewTypes>,
  titleText: string,
  setViewToMain: ($Keys<typeof viewTypes>) => SetViewMainAction,
} */

const SectionTitle = (props /* : Props */) => {
  const isEditView = props.view === viewTypes.EDIT_CATEGORY
  return (
    <Title>
      <TitleBase isEditView={isEditView} onClick={isEditView && props.setViewToMain}>
        UPGRADES
      </TitleBase>
      {isEditView && <span>{` / ${props.titleText}`}</span>}
    </Title>
  )
}

const Title = styled.div`
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding-left: 10px;
  float: left;
  font-family: Oswald, sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: black;
`

const TitleBase = styled.span`
  cursor: ${props => (props.isEditView ? 'pointer' : 'default')};
  color: ${props => (props.isEditView ? 'rgba(84, 135, 233, 1.00)' : 'black')};
`

export default SectionTitle
