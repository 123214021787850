import styled, { css } from 'styled-components'
import CheckboxLine from '../../../../component-lib/Generic/Checkbox/CheckboxLine'
import RadioList from '../../../../component-lib/Generic/RadioButtons/RadioList'
import TextInput from '../../../../component-lib/Generic/TextInputs/TextInput'
import DropdownMenu from '../../../../component-lib/Manager/Menus/DropdownMenu'
import { categoryFormFields, chargeGratuityTypes, chargeServiceChargeTypes } from '../constants'
import CategoryMultiSelect from 'svr/component-lib/Manager/MultiSelect/CategoryMultiSelect'
import { useState, useEffect } from 'react'
import { getFees } from 'mgr/upsells/actions/mainThunks'

/* type Props = {
  isNameShown: boolean,
  isNameShownDisabled: boolean,
  isChargingTax: boolean,
  isChargingGratuity: boolean,
  gratuityPercentage: string,
  gratuityChargeType: string,
  defaultServiceChargePercentage: number,
  defaultGratuityPercentage: number,
  taxGroups: { string: string },
  taxGroupsOptions: [{ string: string }],
  taxGroupSelected: [{ string: string }],
  toggleCategoryField: (field: string) => mixed,
  changeCategoryField: (value: string | number, field: string) => mixed,
  changeCategoryTaxGroupId: (value: [string]) => mixed,
} */
const MultiSelectWrapper = styled.div`
  position: relative;
  width: ${props => props.width}px;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
`

const AdditionalOptions = (props /* : Props */) => {
  const [feesData, setFeesData] = useState(null)

  useEffect(() => {
    const fetchFees = async () => {
      try {
        const fees = getFees()
        const result = await fees(props.venueId)
        setFeesData(
          result.fees.map(fee => {
            fee.isSelected = props.pdrFees.includes(fee.id)
            return fee
          })
        )
      } catch (error) {
        console.error('Error fetching fees:', error)
      }
    }

    fetchFees()
  }, [])

  const serializedFees = Object.entries(feesData || {}).map(([_id, fee]) => ({
      isSelected: fee.isSelected,
      label: `${fee?.name}`,
      id: fee.id,
    }))
    .sort((feeA, feeB) => feeA.label.localeCompare(feeB.label))

  return (
    <AdditionalOptionsWrapper>
      <FieldsHeader>Additional Options</FieldsHeader>
      <CheckboxLine
        field={categoryFormFields.IS_NAME_SHOWN}
        label="Show category name on the widget"
        isSelected={props.isNameShown}
        isDisabled={props.isNameShownDisabled}
        onClick={props.toggleCategoryField}
        customCheckboxLabel={css`
          color: rgba(145, 145, 145, 1);
          font-weight: 300;
        `}
      />
      <CheckboxLine
        field={categoryFormFields.IS_CHARGING_SERVICE_CHARGE}
        label="Apply Service Charge (taxed)"
        isSelected={props.isChargingServiceCharge}
        onClick={props.toggleCategoryField}
        customCheckboxLabel={css`
          color: rgba(145, 145, 145, 1);
          font-weight: 300;
        `}
      />
      {props.isChargingServiceCharge && (
        <RadioList
          field={categoryFormFields.SERVICE_CHARGE_TYPE}
          selectedValue={props.serviceChargeType}
          onClick={props.changeCategoryField}
          customRadioLabel={css`
            color: rgba(145, 145, 145, 1);
            font-weight: 300;
          `}
          customRadioListWrapper={css`
            margin-left: 24px;
          `}
          options={[
            {
              value: chargeServiceChargeTypes.DEFAULT_SERVICE_CHARGE,
              label: `Default Service Charge (${props.defaultServiceChargePercentage}%)`,
            },
            {
              value: chargeServiceChargeTypes.SPECIFIC_SERVICE_CHARGE,
              label: 'Specific Service Charge',
              child: (
                <TextInput
                  field={categoryFormFields.SERVICE_CHARGE_PERCENTAGE}
                  value={props.serviceChargePercentage}
                  onChange={props.changeCategoryField}
                  inputType={TextInput.inputTypes.POSITIVE_NUMBER}
                  charLimit={5}
                  customInput={css`
                    text-align: center;
                  `}
                  customInputWrapper={css`
                    width: 80px;
                  `}
                />
              ),
            },
          ]}
        />
      )}
      <CheckboxLine
        field={categoryFormFields.IS_CHARGING_TAX}
        label="Apply tax"
        isDisabled={(props.taxGroupsOptions?.length ?? 0) === 0}
        isSelected={props.isChargingTax}
        onClick={props.toggleCategoryField}
        customCheckboxLabel={css`
          color: rgba(145, 145, 145, 1);
          font-weight: 300;
        `}
      />
      {props.isChargingTax && (
        <DropdownMenu
          field={categoryFormFields.TAX_GROUP_ID}
          style={{}}
          optionsContainerStyle={{}}
          choices={props.taxGroupsOptions}
          showNavigation
          onChangeHandler={props.changeCategoryTaxGroupId}
          isLightTheme
          borderAreaStyle={{}}
          selectedValues={props.taxGroupSelected}
        />
      )}
      <CheckboxLine
        field={categoryFormFields.IS_CHARGING_GRATUITY}
        label="Apply Gratuity (not taxed)"
        isSelected={props.isChargingGratuity}
        onClick={props.toggleCategoryField}
        customCheckboxLabel={css`
          color: rgba(145, 145, 145, 1);
          font-weight: 300;
        `}
      />
      {props.isChargingGratuity && (
        <RadioList
          field={categoryFormFields.GRATUITY_CHARGE_TYPE}
          selectedValue={props.gratuityChargeType}
          onClick={props.changeCategoryField}
          customRadioLabel={css`
            color: rgba(145, 145, 145, 1);
            font-weight: 300;
          `}
          customRadioListWrapper={css`
            margin-left: 24px;
          `}
          options={[
            {
              value: chargeGratuityTypes.DEFAULT_GRATUITY,
              label: `Default Gratuity (${props.defaultGratuityPercentage}%)`,
            },
            {
              value: chargeGratuityTypes.SPECIFIC_GRATUITY,
              label: 'Specific Gratuity',
              child: (
                <TextInput
                  field={categoryFormFields.GRATUITY_PERCENTAGE}
                  value={props.gratuityPercentage}
                  onChange={props.changeCategoryField}
                  inputType={TextInput.inputTypes.POSITIVE_NUMBER}
                  charLimit={5}
                  customInput={css`
                    text-align: center;
                  `}
                  customInputWrapper={css`
                    width: 80px;
                  `}
                />
              ),
            },
            {
              value: chargeGratuityTypes.CLIENT_GRATUITY,
              label: 'Allow Client to Select Gratuity',
              child: (
                <CheckboxLine
                  field={categoryFormFields.REQUIRE_CHARGING_GRATUITY}
                  label="Require Client to Select"
                  isSelected={props.requireGratuityCharge}
                  onClick={props.toggleCategoryField}
                  customCheckboxLabel={css`
                    color: rgba(145, 145, 145, 1);
                    font-weight: 300;
                  `}
                />
              ),
            },
          ]}
        />
      )}
      {props.advancedPDRUpgrades && props.isFlexibleFeesIntegrationEnabled && (
        <>
          <FeesTitle>Fees</FeesTitle>
          {serializedFees && (
            <CategoryMultiSelect
              width={1000}
              onSelect={b => {
                const selected = Object.entries(b)
                  .filter(([idx, fee]) => fee.isSelected)
                  .map(([idx, fee]) => fee.id)
                props.changeCategoryField(selected, 'pdrFees')
                setFeesData(
                  Object.entries(b).map(([idx, v]) => ({
                    isSelected: v.isSelected,
                    name: v.label,
                    fee_amount: v.label,
                    id: v.id,
                  }))
                )
              }}
              options={serializedFees}
            />
          )}
        </>
      )}
    </AdditionalOptionsWrapper>
  )
}
const FeesTitle = styled.span`
  margin-top: 40px;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 16px;
  color: ${props => props.theme.fontLabelColor};
`

const AdditionalOptionsWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 50px;
  height: auto;
`

const FieldsHeader = styled.fieldset`
  border: none;
  display: inline-block;
  font-size: 15px;
  font-family: inherit;
  font-weight: 400;
  margin-bottom: 5px;
  color: ${props => props.theme.fontLabelColor};
`

const PercentageSign = styled.span`
  display: inline-block;
  font-size: 18px;
  color: ${props => props.theme.fontLabelColor};
`

export default AdditionalOptions
