// /* @flow */
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ModalCover from '../../../../component-lib/Generic/Modals/ModalCover'
import categoryActions from '../actions/categories'
import * as categoryThunks from '../actions/categoriesThunks'
import { displayModal } from '../actions/main'
import ModalFooter from '../components/ModalFooter'
import ModalHeader from '../components/ModalHeader'
/* import type {
  CategoryAction,
  DisplayModalMainAction,
} from '../flowTypes/actions' */
/* import type { PromiseAction } from '../flowTypes/redux' */

/* type Props = {
  deleteCategory: string => PromiseAction,
  cancelDeleteCategory: () => CategoryAction,
  displayModal: (string | void) => DisplayModalMainAction,
  deleteCategoryId: string,
} */

class DeleteCategoryModal extends React.PureComponent /* <Props> */ {
  closeModal = this.props.displayModal.bind(this, undefined)

  deleteCategory = this.props.deleteCategory.bind(this, this.props.deleteCategoryId)

  render() {
    return (
      <ModalCover>
        <ModalSection>
          <ModalHeader headerText="ARE YOU SURE YOU WANT TO DELETE THIS CATEGORY?" clickCloseModal={this.closeModal} />
          <ModalBody>
            {`By deleting this category,
            all inventory types created within this category will also be deleted.
            This will not affect any Upsell purchases in this category made to date.`}
            <br />
            <br />
            This action cannot be undone
          </ModalBody>
          <ModalFooter
            primaryButtonText="Nevermind"
            secondaryButtonText="Yes, delete"
            onPrimaryButtonClick={this.props.cancelDeleteCategory}
            onSecondaryButtonClick={this.deleteCategory}
          />
        </ModalSection>
      </ModalCover>
    )
  }
}

const ModalSection = styled.div`
  width: 60vw;
  max-width: 600px;
  height: 300px;
  background-color: white;
`

const ModalBody = styled.div`
  padding: 18px 14px;
  color: #858e95;
  font-size: 15px;
  line-height: 30px;
  font-weight: 100;
  font-family: Roboto;
`

const mapStateToProps = state => ({
  deleteCategoryId: state.categories.deleteCategoryId,
})

const mapDispatchToProps = {
  displayModal,
  deleteCategory: categoryThunks.deleteCategory,
  cancelDeleteCategory: categoryActions.cancelDeleteCategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCategoryModal)
