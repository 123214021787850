// /* @flow */
import _ from 'lodash'
import { srDelete, srPost, srPostJson, srPut } from '@sevenrooms/core/api'
import * as actionTypes from '../actionTypes'
import { selectNextInventorySortOrder } from '../selectors'
import * as utils from '../utils/transformData'
/* import type { AddInventoryAction } from '../flowTypes/actions' */
/* import type { Dispatch, GetState } from '../flowTypes/redux' */

const API_NAMESPACE = '/api-yoa'

export const addInventory =
  (categoryId /* : string */) => (dispatch /* : Dispatch */, getState /* : GetState */) /* : AddInventoryAction */ =>
    dispatch({
      type: actionTypes.ADD_INVENTORY,
      categoryId,
      sortOrder: selectNextInventorySortOrder(getState()),
    })

export const saveInventory = () => async (dispatch /* : Dispatch */, getState /* : GetState */) => {
  const state = getState()
  const { venueId } = state.main
  const inventoryEditState = state.inventory.edit
  if (inventoryEditState.id) {
    dispatch(inventoryPutAttempt())
    const putResponse = await srPut(
      `${API_NAMESPACE}/venue/upsells/inventory?venue=${venueId}`,
      utils.transformInventoryStateToPutApi(inventoryEditState, venueId),
      { json: true }
    )
    return putResponse.status === 200
      ? dispatch(inventoryPutSuccess(utils.transformInventoryApiDataToState(putResponse.data.upsell_inventory, state.main.reservationTags)))
      : dispatch(inventoryPutFailure())
  }
  dispatch(inventoryPostAttempt())
  const postResponse = await srPostJson(
    `${API_NAMESPACE}/venue/upsells/inventory?venue=${venueId}`,
    utils.transformInventoryStateToPostApi(inventoryEditState, venueId)
  )
  return postResponse.status === 200
    ? dispatch(inventoryPostSuccess(utils.transformInventoryApiDataToState(postResponse.data.upsell_inventory, state.main.reservationTags)))
    : dispatch(inventoryPostFailure())
}

export const deleteInventory =
  (inventoryId /* : string */, categoryId /* : string */) => async (dispatch /* : Dispatch */, getState /* : GetState */) => {
    const { venueId } = getState().main
    dispatch(inventoryDeleteAttempt(inventoryId))
    const deleteResponse = await srDelete(`${API_NAMESPACE}/venue/upsells/inventory`, {
      venue: venueId,
      inventory_id: inventoryId,
    })
    return deleteResponse.status === 200
      ? dispatch(inventoryDeleteSuccess(inventoryId, categoryId))
      : dispatch(inventoryDeleteFailure(inventoryId))
  }

const reorderInventories = _.debounce(async (dispatch, getState) => {
  const state = getState()
  const inventoryViewOrder = state.categories.edit.inventory
  const { venueId } = state.main
  dispatch(reorderInventoriesAttempt())
  const reorderResponse = await srPost(
    `/manager/${venueId}/manage/upsell_inventory/order/save`,
    {
      inventory_view_order: inventoryViewOrder,
    },
    { skipLoadClickBlocker: true }
  )
  return reorderResponse.statusCode === 200
    ? dispatch(reorderInventoriesSuccess(utils.transformDataFromOrderApi(reorderResponse.payload.inventory_order)))
    : dispatch(reorderInventoriesFailure())
}, 2500)

export const moveInventoryUp = (inventoryId /* : string */) => async (dispatch /* : Dispatch */, getState /* : GetState */) => {
  dispatch({
    type: actionTypes.MOVE_INVENTORY_UP,
    inventoryId,
  })
  reorderInventories(dispatch, getState)
}

export const moveInventoryDown = (inventoryId /* : string */) => async (dispatch /* : Dispatch */, getState /* : GetState */) => {
  dispatch({
    type: actionTypes.MOVE_INVENTORY_DOWN,
    inventoryId,
  })
  reorderInventories(dispatch, getState)
}

// HTTP Action creators
const inventoryPutAttempt = () => ({ type: actionTypes.INVENTORY_PUT_ATTEMPT })
const inventoryPutSuccess = inventoryEntity => ({
  type: actionTypes.INVENTORY_PUT_SUCCESS,
  inventoryEntity,
})
const inventoryPutFailure = () => ({ type: actionTypes.INVENTORY_PUT_FAILURE })
const inventoryPostAttempt = () => ({
  type: actionTypes.INVENTORY_POST_ATTEMPT,
})
const inventoryPostSuccess = inventoryEntity => ({
  type: actionTypes.INVENTORY_POST_SUCCESS,
  inventoryEntity,
})
const inventoryPostFailure = () => ({
  type: actionTypes.INVENTORY_POST_FAILURE,
})
const inventoryDeleteAttempt = inventoryId => ({
  type: actionTypes.INVENTORY_DELETE_ATTEMPT,
  inventoryId,
})
const inventoryDeleteSuccess = (inventoryId, categoryId) => ({
  type: actionTypes.INVENTORY_DELETE_SUCCESS,
  inventoryId,
  categoryId,
})
const inventoryDeleteFailure = inventoryId => ({
  type: actionTypes.INVENTORY_DELETE_FAILURE,
  inventoryId,
})
const reorderInventoriesAttempt = () => ({
  type: actionTypes.REORDER_INVENTORIES_ATTEMPT,
})
const reorderInventoriesSuccess = sortOrderMap => ({
  type: actionTypes.REORDER_INVENTORIES_SUCCESS,
  sortOrderMap,
})
const reorderInventoriesFailure = () => ({
  type: actionTypes.REORDER_INVENTORIES_FAILURE,
})
