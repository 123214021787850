// /* @flow */
import * as React from 'react'
import RichTextEditor from 'react-rte'
import styled from 'styled-components'
import { inventoryFormFields } from '../constants'
import './InventoryRichTextEditor.css'

/* type Props = {
  changeInventoryField: (field: string, value: string | number) => mixed,
  value: mixed,
} */

class InventoryRichTextEditor extends React.PureComponent /* <Props> */ {
  toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' },
    ],
  }

  handleRichTextChange = this.props.changeInventoryField.bind(this, inventoryFormFields.DESCRIPTION)

  render() {
    return (
      <TextEditorWrapper className="InventoryRichTextEditor">
        <RichTextEditor value={this.props.value} onChange={this.handleRichTextChange} toolbarConfig={this.toolbarConfig} />
      </TextEditorWrapper>
    )
  }
}

const TextEditorWrapper = styled.div`
  clear: both;
  width: 100%;
`

export default InventoryRichTextEditor
