import React from 'react'

import { srGet, srUpload } from '@sevenrooms/core/api'

const DEFAULT_FILE_SIZE_LIMIT_MB = 1

function FileUploader({ uploaderId, trigger, limit = 1024 * 1024 * DEFAULT_FILE_SIZE_LIMIT_MB, extensions, onInit, onComplete, onError }) {
  return (
    <div className="file-uploader">
      <label>
        {trigger}
        <input
          id={uploaderId}
          type="file"
          multiple={false}
          accept={extensions && extensions.map(e => `.${e}`).join(',')}
          style={{ display: 'none' }}
          onChange={e => {
            const { files } = e.currentTarget

            if (onInit) {
              const error = onInit(files)
              if (error) {
                onError(error)
                return
              }
            }

            const file = files[0]
            if (file.size > limit) {
              onError(`${file.name} exceeds the ${limit / (1024 * 1024)}MB file size limit!`)
              return
            }
            if (extensions) {
              const parts = file.name.split('.')
              const ext = parts[parts.length - 1]
              if (extensions.indexOf(ext) === -1) {
                onError(`Invalid file type. Only ${extensions.join(', ')} files are allowed.`)
                return
              }
            }
            srGet('/upload-url?rurl=1', {
              method: 'POST',
              headers: { 'X-CSRFToken': Pmp.Client.Static.Csrf },
            })
              .then(r => srUpload(r.upload_url, file))
              .then(({ post_key, url_key }) => onComplete(url_key, post_key, file))
          }}
        />
      </label>
    </div>
  )
}

export default FileUploader

// the below does not work for our current version of react-dropzone 3.7
// latest is 11.2
// with the below, you should be able to specify what the dropzone should look like
/*
  return (
    <Dropzone
      maxSize={maxSize}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
    >
      {({ getRootProps, getInputProps }) => (
        <div>
          <label htmlFor={uploaderId} {...getRootProps({ className: 'dropzone' })}>
            {trigger}
          </label>
          <input id={uploaderId} {...getInputProps()} style={{ display: 'none' }} />
        </div>
      )}
    </Dropzone>
  )
*/
