// /* @flow */
import _ from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import GenericTagsDropDown from 'mgr/lib/components/GenericTagsDropDown'
import CheckboxLine from 'svr/component-lib/Generic/Checkbox/CheckboxLine'
import { validateNotEmpty, validateCurrency, validateDefault } from '../../../../common/Validators'
import ModalCover from '../../../../component-lib/Generic/Modals/ModalCover'
import CurrencyInput from '../../../../component-lib/Generic/TextInputs/CurrencyInput'
import TextInput from '../../../../component-lib/Generic/TextInputs/TextInput'
import { changeInventoryField, changeModifiersField, validateInventory, cancelInventory, toggleInventoryField, toggleSelectModifiersModal } from '../actions/inventory'
import { saveInventory } from '../actions/inventoryThunks'
import { displayModal } from '../actions/main'
import * as SlideoutActions from '../actions/SlideoutActions'
import * as actionTypes from '../actionTypes'
import FileUploader from '../components/FileUpload'
import InventoryRichTextEditor from '../components/InventoryRichTextEditor'
import ModalFooter from '../components/ModalFooter'
import ModalHeader from '../components/ModalHeader'
import UpgradeImage from '../components/UpgradeImage'
import { inventoryFormFields } from '../constants'
import StandardButton from '../../../../component-lib/Generic/Buttons/StandardButton'
import SelectModifiersModal from './SelectModifiersModal'
/* import type { Action } from '../flowTypes/actions' */
/* import type { ThunkAction } from '../flowTypes/redux' */

/* type Props = {
  changeInventoryField: (field: string, value: string | number) => mixed,
  validateInventory: ({ [string]: boolean }) => Action,
  cancelInventory: () => Action,
  showNotificationError: ({ message: string }) => Action,
  saveInventory: () => ThunkAction,
  closeModal: () => void,
  inventoryTypeName: string,
  price: string,
  description: mixed,
  reservationTags: mixed[],
  tagGroups: mixed[],
  currencySymbol: string,
  venueId: string,
  formErrors: { [string]: mixed },
  previewImageUrlKey: string,
  isPriceHidden: boolean,
  toggleInventoryField: (field: string, value: boolean) => ThunkAction
} */

class EditInventoryModal extends React.PureComponent /* <Props> */ {
  inventoryInputs = {}

  handleInventoryTypeNameChange = this.props.changeInventoryField.bind(this, inventoryFormFields.INVENTORY_TYPE_NAME)

  handleInventoryPriceChange = this.props.changeInventoryField.bind(this, inventoryFormFields.PRICE)

  handleInventoryImageChange = this.props.changeInventoryField.bind(this, inventoryFormFields.PREVIEW_IMAGE)

  handleInventoryTagsChange = this.props.changeInventoryField.bind(this, inventoryFormFields.RESERVATION_TAGS)

  handleModifiersChange = this.props.changeModifiersField.bind(this, inventoryFormFields.MODIFIERS_GROUPS_SELECTED)

  _validateInventory = () => {
    const recentFormErrors = _.reduce(
      this.inventoryInputs,
      (accum, input, key) => (input.validate() ? accum : { ...accum, [key]: true }),
      {}
    )
    this.props.validateInventory(recentFormErrors)
    return recentFormErrors
  }

  _saveInventory = async () => {
    const saveInventoryResult = await this.props.saveInventory()
    const isSuccessfulSave =
      saveInventoryResult.type === actionTypes.INVENTORY_POST_SUCCESS || saveInventoryResult.type === actionTypes.INVENTORY_PUT_SUCCESS
    return isSuccessfulSave
  }

  handleSaveInventoryClick = async () => {
    const formErrors = this._validateInventory()
    if (_.isEmpty(formErrors)) {
      const isSuccessfulSave = await this._saveInventory()
      if (isSuccessfulSave) {
        this.props.closeModal()
      }
    }
  }

  render() {
    const disablePriceInput = this.props.isPriceHidden
    const selectModifiersModalShow = this.props.selectModifiersModalShow
    return (
      <ModalCover
        customModalWrapper={css`
          top: 48%;
          overflow: scroll;
        `}
      >
        <ModalSection>
          <ModalHeader headerText="ADD UPGRADE" clickCloseModal={this.props.closeModal} />
          <ModalBody>
            <InputRow>
              <TextInput
                field={inventoryFormFields.INVENTORY_TYPE_NAME}
                value={this.props.inventoryTypeName}
                label="Inventory Type Name*"
                onChange={this.handleInventoryTypeNameChange}
                isValid={!this.props.formErrors.inventoryTypeName}
                validator={validateNotEmpty}
                ref={input => {
                  this.inventoryInputs.inventoryTypeName = input
                }}
                // @TODO: VX-3861
                customInput={css`
                  color: ${props => props.theme.fontInputColor};
                  width: 100% !important;
                  font-size: 18px !important;
                  background-color: rgb(255, 255, 255) !important;
                  color: rgb(102, 102, 102) !important;
                  padding: 0px 4px !important;
                  border-width: 2px !important;
                  border-style: solid !important;
                  border-color: rgb(236, 236, 236) !important;
                `}
                customInputLabel={css`
                  color: ${props => props.theme.fontLabelColor};
                `}
                customInputWrapper={css`
                  width: 50%;
                  margin: 0 15px 0px 0;
                  float: left;
                `}
              />
              <CurrencyInput
                field={inventoryFormFields.PRICE}
                value={this.props.price}
                label="Price*"
                placeholder="0.00"
                currencySymbol={disablePriceInput ? '' : this.props.currencySymbol}
                onChange={this.handleInventoryPriceChange}
                isValid={!this.props.formErrors.price}
                validator={validateCurrency}
                ref={input => {
                  this.inventoryInputs.price = input
                }}
                disabled={disablePriceInput}
                customInput={
                  disablePriceInput
                    ? css`
                        color: transparent;
                        background-color: ${props => props.theme.disableInputBgColor};
                        user-select: none;
                        -moz-user-select: none;
                        -webkit-user-select: none;
                      `
                    : css`
                        color: ${props => props.theme.fontInputColor};
                      `
                }
                customInputLabel={css`
                  color: ${props => props.theme.fontLabelColor};
                `}
                customInputWrapper={css`
                  float: left;
                  margin: 0 15px 0px 0;
                `}
              />
              <CheckboxLine
                field={inventoryFormFields.IS_PRICE_HIDDEN}
                label="Don't show price"
                isSelected={this.props.isPriceHidden}
                onClick={this.props.toggleInventoryField}
                customCheckboxWrapper={css`
                  float: left;
                  margin-top: 24px;
                `}
                customCheckboxLabel={css`
                  color: rgba(145, 145, 145, 1);
                  font-weight: 300;
                `}
              />
            </InputRow>
            <InputRow>
              <InventoryRichTextEditor value={this.props.description} changeInventoryField={this.props.changeInventoryField} />
            </InputRow>
            <InputRow>
              <InputRowHeader>What image would you like to add to your Upgrade?</InputRowHeader>
              {this.props.previewImageUrlKey ? (
                <UpgradeImage
                  src={`/.h/download/${this.props.previewImageUrlKey.replace(/^"(.*)"$/, '$1')}`}
                  onDelete={() => this.handleInventoryImageChange(null)}
                />
              ) : (
                <FileUploader
                  uploaderId="upgrade-image-uploader"
                  trigger={<EmptyPhoto />}
                  extensions={['png', 'jpeg', 'jpg', 'gif']}
                  onComplete={url_key => this.handleInventoryImageChange(url_key)}
                  onError={message => {
                    this.props.showNotificationError(message)
                  }}
                />
              )}
            </InputRow>
            <InputRow>
              <GenericTagsDropDown
                venueId={this.props.venueId}
                name="Add reservation tags based on purchasing this upgrade"
                tagGroups={this.props.tagGroups}
                selectedTags={this.props.reservationTags}
                onChangeHandler={this.handleInventoryTagsChange}
                height="44px"
                width="100%"
              />
            </InputRow>
            {this.props.advancedPDRUpgrades && (
              <StandardButton field={inventoryFormFields.SELECT_MODIFIERS_MODAL_SHOW} onClick={this.props.toggleSelectModifiersModal} customButton={ButtonPrimaryStyle}>
                Manage Modifiers
              </StandardButton>
            )}
            {selectModifiersModalShow && (
              <SelectModifiersModal
                modifiersGroups={this.props.modifiersGroups}
                modifiers={this.props.modifiers}
                close={this.props.toggleSelectModifiersModal}
                handleModifiersChange={this.handleModifiersChange}
                modifiersGroupsSelected={this.props.modifiersGroupsChecked}
              />
            )}
            {this.props.posIntegrationType === 'TEVALIS' ? (
              <InputRow>
                <TextInput
                  field={inventoryFormFields.POS_ITEM_ID}
                  value={this.props.posItemId}
                  label="POS Item ID"
                  onChange={this.props.changeInventoryField.bind(this, inventoryFormFields.POS_ITEM_ID)}
                  isValid={!this.props.formErrors.posItemId}
                  validator={validateDefault}
                  ref={input => {
                    this.inventoryInputs.posItemId = input
                  }}
                  // @TODO: VX-3861
                  customInput={css`
                    color: ${props => props.theme.fontInputColor};
                    width: 100% !important;
                    font-size: 18px !important;
                    background-color: rgb(255, 255, 255) !important;
                    color: rgb(102, 102, 102) !important;
                    padding: 0px 4px !important;
                    border-width: 2px !important;
                    border-style: solid !important;
                    border-color: rgb(236, 236, 236) !important;
                  `}
                  customInputLabel={css`
                    color: ${props => props.theme.fontLabelColor};
                  `}
                  customInputWrapper={css`
                    width: 100%;
                    margin: 0 15px 0px 0;
                    float: left;
                  `}
                />
              </InputRow>
            ) : (
              ''
            )}
          </ModalBody>
          <ModalFooter
            customModalFooterWrapper={css`
              position: sticky;
              background: white;
            `}
            primaryButtonText="Add"
            secondaryButtonText="Cancel"
            onPrimaryButtonClick={this.handleSaveInventoryClick}
            onSecondaryButtonClick={this.props.cancelInventory}
          />
        </ModalSection>
      </ModalCover>
    )
  }
}

const InputRowHeader = styled.div`
  color: ${props => props.theme.darkGrey};
  font-size: 15px;
  padding: 0 0 4px 10px;
`

const EmptyPhoto = styled.div`
  background: #f5f5f5 url('${window.globalInit.mediaUrl}images/add_picture.png') 50% 50% no-repeat;
  border: 1px dashed #ccc;
  cursor: pointer;
  float: left;
  position: relative;
  width: 260px;
  height: 194px;
  background-size: 30px;
`

const ModalSection = styled.div`
  width: 80vw;
  max-width: 800px;
  background-color: white;
`

const ModalBody = styled.div`
  padding: 18px 14px;
  overflow-y: auto;
`

const InputRow = styled.div`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 22px;
  }

  ::after {
    content: '';
    display: block;
    clear: both;
  }
`

const ButtonPrimaryStyle = css`
  float: left;
  height: 44px;
  line-height: 44px;
  margin: 8px 15px 8px 0;
  color: white;
  background-color: rgba(120, 156, 239, 1);
`

const mapStateToProps = state => ({
  inventoryTypeName: state.inventory.edit.inventoryTypeName,
  price: state.inventory.edit.price,
  reservationTags: state.inventory.edit.reservationTags,
  description: state.inventory.edit.description,
  previewImageUrlKey: state.inventory.edit.previewImageUrlKey,
  modifiers: state.main.modifiers,
  modifiersGroups: state.main.modifiersGroups,
  tagGroups: state.main.reservationTags,
  currencySymbol: state.main.currencySymbol,
  venueId: state.main.venueId,
  formErrors: state.inventory.edit.formErrors,
  isPriceHidden: state.inventory.edit.isPriceHidden,
  selectModifiersModalShow: state.inventory.edit.selectModifiersModalShow,
  posIntegrationType: state.main.posIntegrationType,
  posItemId: state.inventory.edit.posItemId,
  modifiersGroupsChecked: state.inventory.edit.modifiersGroupsChecked,
  advancedPDRUpgrades: state.categories.edit.advancedPDRUpgrades,
})

const mapDispatchToProps = {
  closeModal: () => displayModal(),
  changeInventoryField,
  changeModifiersField,
  validateInventory,
  cancelInventory,
  saveInventory,
  showNotificationError: SlideoutActions.showNotificationError,
  toggleInventoryField,
  toggleSelectModifiersModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditInventoryModal)
