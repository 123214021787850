// /* @flow */
import * as React from 'react'
import styled, { css } from 'styled-components'
import StandardButton from '../../../../component-lib/Generic/Buttons/StandardButton'

/* type Props = {
  onSaveClick: () => mixed,
  onCancelClick: () => mixed,
} */

const FooterBar = (props /* : Props */) => (
  <FooterBarWrapper>
    <StandardButton onClick={props.onSaveClick} customButton={ButtonSaveStyle}>
      Save
    </StandardButton>
    <StandardButton onClick={props.onCancelClick} customButton={ButtonCancelStyle}>
      Cancel
    </StandardButton>
  </FooterBarWrapper>
)

const FooterBarWrapper = styled.div`
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(218, 218, 218);
  bottom: 0px;
  height: 54px;
  position: fixed;
  right: 0px;
  width: 100%;
  z-index: 97;
`

const ButtonCancelStyle = css`
  float: right;
  height: 44px;
  line-height: 44px;
  margin: 5px 15px 5px 0;
  background-color: rgba(235, 235, 235, 1);
  color: rgba(134, 134, 140, 1);
`

const ButtonSaveStyle = css`
  float: right;
  height: 44px;
  line-height: 44px;
  margin: 5px 15px 5px 0;
  color: white;
  background-color: rgba(120, 156, 239, 1);
`

export default FooterBar
