import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import { NotificationLevel } from 'mgr/lib/utils/Constants'
import * as actionTypes from '../actionTypes'

export const initialize = globalInit => ({
  type: GlobalActionTypes.INITIALIZE,
  globalInit,
})

export const showNotificationBanner = notificationBannerState => ({
  type: actionTypes.SHOW_NOTIFICATION_BANNER,
  notificationBannerState,
})

export const showNotificationError = message =>
  showNotificationBanner({
    message,
    level: NotificationLevel.ERROR,
    visible: true,
  })

export const closeNotificationBanner = () => ({
  type: actionTypes.CLOSE_NOTIFICATION_BANNER,
})
export const clickNotificationBannerAction = () => ({
  type: actionTypes.CLICK_NOTIFICATION_BANNER_ACTION,
})
