// /* @flow */
import * as actionTypes from '../actionTypes'
import { viewTypes, modalTypes } from '../constants'
/* import type {
  SetViewMainAction,
  DisplayModalMainAction,
} from '../flowTypes/actions' */

export const setView = (view /* : $Keys<typeof viewTypes> */) /* : SetViewMainAction */ => ({
  type: actionTypes.SET_VIEW,
  view,
})

export const displayModal = (modal /* ? */ /* : $Keys<typeof modalTypes> */) /* : DisplayModalMainAction */ => ({
  type: actionTypes.DISPLAY_MODAL,
  modal,
})
