// /* @flow */
export const swapArrayItems = (array /* : Array<string> */, indexFrom /* : number */, indexTo /* : number */) => {
  if (!array || indexFrom < 0 || indexTo > array.length) {
    throw new Error(`Invalid arguments: ${array.toString()}, ${indexFrom}, ${indexTo}`)
  }
  const newArray = array.slice()
  const temp = newArray[indexFrom]
  newArray[indexFrom] = newArray[indexTo]
  newArray[indexTo] = temp
  return newArray
}
