// /* @flow */
import * as React from 'react'
import { connect } from 'react-redux'
import styled, { ThemeProvider } from 'styled-components'
import GlobalNotificationBanner from 'mgr/layout/GlobalNotificationBanner'
import Theme from 'mgr/layout/Theme'
import { setView } from '../actions/main'
import { getData } from '../actions/mainThunks'
import * as SlideoutActions from '../actions/SlideoutActions'
import UpsellsStyleTag from '../components/UpsellsStyleTag'
import { viewTypes, modalTypes } from '../constants'
import DeleteCategoryModal from './DeleteCategoryModal'
import EditCategorySection from './EditCategorySection'
import EditInventoryModal from './EditInventoryModal'
import SectionHeader from './SectionHeader'
import ViewCategoriesSection from './ViewCategoriesSection'
/* import type { SetViewMainAction } from '../flowTypes/actions' */
/* import type { PromiseAction } from '../flowTypes/redux' */

/* type Props = {
  view: $Keys<typeof viewTypes>,
  modal: $Keys<typeof modalTypes>,
  getData: () => PromiseAction,
  setView: ($Keys<typeof viewTypes>) => SetViewMainAction,
} */

class MainSection extends React.PureComponent /* <Props> */ {
  componentWillMount() {
    this.props.getData()
    this.props.initialize(globalInit)
  }

  setViewToMain = this.props.setView.bind(this, viewTypes.VIEW_CATEGORIES)

  viewMap = {
    [viewTypes.VIEW_CATEGORIES]: ViewCategoriesSection,
    [viewTypes.EDIT_CATEGORY]: EditCategorySection,
  }

  modalMap = {
    [modalTypes.EDIT_INVENTORY]: EditInventoryModal,
    [modalTypes.DELETE_CATEGORY]: DeleteCategoryModal,
  }

  render() {
    const ViewSection = this.viewMap[this.props.view]
    const Modal = this.modalMap[this.props.modal]
    const { notificationBannerState } = this.props

    return (
      <ThemeProvider theme={Theme}>
        <MainSectionWrapper>
          <GlobalNotificationBanner
            {...notificationBannerState}
            handleClose={this.props.closeNotificationBanner}
            handleAction={this.props.clickNotificationBannerAction}
          />
          <UpsellsStyleTag />
          <SectionHeader setViewToMain={this.setViewToMain} />
          <ViewSection />
          {this.props.modal && <Modal />}
        </MainSectionWrapper>
      </ThemeProvider>
    )
  }
}

const MainSectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  font-family: Roboto;
`

const mapStateToProps = state => ({
  view: state.main.view,
  modal: state.main.modal,
  notificationBannerState: state.inventory.notificationBannerState,
})

const mapDispatchToProps = {
  getData,
  setView,
  initialize: SlideoutActions.initialize,
  closeNotificationBanner: SlideoutActions.closeNotificationBanner,
  clickNotificationBannerAction: SlideoutActions.clickNotificationBannerAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainSection)
