import React from 'react'
import CheckboxLine from 'svr/component-lib/Generic/Checkbox/CheckboxLine'
import { categoryFormFields } from '../constants'
import { css } from 'styled-components'

const AdvancedUpgradeCheck = props => (
  <CheckboxLine
    field={categoryFormFields.ADVANCED_UPGRADES}
    label="This is a private dining category"
    isSelected={props.advancedPDRUpgrades}
    onClick={props.toggleCategoryField}
    customCheckboxLabel={css`
      color: rgba(145, 145, 145, 1);
      font-weight: 300;
    `}
  />
)

export default AdvancedUpgradeCheck
