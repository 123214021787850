import _ from 'lodash'
import { createSelector } from 'reselect'
import { minQuantityTypes } from './constants'

const getInventoryList = state => state.categories.edit.inventory

const getInventoryEntities = state => state.inventory.entities

export const selectCategoryInventory = createSelector([getInventoryList, getInventoryEntities], (inventoryIdList, inventoryEntities) =>
  _.reverse(_.map(inventoryIdList, inventoryId => inventoryEntities[inventoryId]))
)

const getCategoryViewOrder = state => state.categories.viewOrder

export const selectCategoryViewOrder = createSelector([getCategoryViewOrder], viewOrder => _.reverse(viewOrder.slice()))

const getCategoryEntities = state => state.categories.entities

export const selectNextCategorySortOrder = createSelector(
  [getCategoryEntities],
  categoryEntities => 1 + Math.max(..._.map(categoryEntities, entity => entity.sortOrder))
)

export const selectNextInventorySortOrder = createSelector(
  [getInventoryList, getInventoryEntities],
  (inventoryIdList, inventoryEntities) => {
    if (!inventoryIdList.length) {
      return 0
    }
    const sortOrderArr = _.map(inventoryIdList, inventoryId => inventoryEntities[inventoryId].sortOrder)
    return 1 + Math.max(...sortOrderArr)
  }
)

const getMinQuantityType = state => state.categories.edit.minQuantityType

export const selectIsNameShownDisabled = createSelector(
  [getMinQuantityType],
  minQuantityType => minQuantityType !== minQuantityTypes.NONE_REQUIRED
)
