// /* @flow */
import * as actionTypes from '../actionTypes'
import { viewTypes, modalTypes } from '../constants'
/* import type { Action } from '../flowTypes/actions' */

/* export type MainState = {
  +view: $Keys<typeof viewTypes>,
  +modal?: $Keys<typeof modalTypes>,
  +venueId: string,
  +mediaUrl: string,
  +taxPercentage?: number,
  +defaultServiceChargePercentage?: number,
  +defaultGratuityPercentage?: number,
  +currencySymbol: string,
  +reservationTags: mixed[],
} */

const getInitialState = () => {
  const venueInfoJson = JSON.parse(getVarFromDOM('venueInfoJson'))
  const taxGroupsData = venueInfoJson.tax_groups
  const { taxGroups, taxGroupsOptions } = getTaxGroupItems(taxGroupsData)
  return {
    view: viewTypes.VIEW_CATEGORIES,
    modal: undefined,
    venueId: getVarFromDOM('_venue_id'),
    mediaUrl: getVarFromDOM('_media_url'),
    taxPercentage: venueInfoJson.tax_rate,
    defaultServiceChargePercentage: venueInfoJson.default_service_charge,
    defaultGratuityPercentage: venueInfoJson.default_gratuity,
    currencySymbol: venueInfoJson.currency_symbol || '$',
    taxGroups,
    taxGroupsOptions,
    reservationTags: venueInfoJson.reservation_tags,
    posIntegrationType: venueInfoJson.pos_integration_type,
  }
}

export default (state /* : MainState */ = getInitialState(), action /* : Action */) /* : MainState */ => {
  switch (action.type) {
    case actionTypes.SET_VIEW:
      return { ...state, view: action.view }
    case actionTypes.DISPLAY_MODAL:
      return { ...state, modal: action.modal }
    case actionTypes.ADD_CATEGORY:
    case actionTypes.EDIT_CATEGORY:
    case actionTypes.CATEGORY_COPY_SUCCESS:
      return { ...state, view: viewTypes.EDIT_CATEGORY }
    case actionTypes.CANCEL_CATEGORY:
    case actionTypes.SAVE_CATEGORY:
      return { ...state, view: viewTypes.VIEW_CATEGORIES }
    case actionTypes.CANCEL_INVENTORY:
      return { ...state, modal: undefined }
    case actionTypes.COPY_INVENTORY:
    case actionTypes.EDIT_INVENTORY:
      return { ...state, modal: modalTypes.EDIT_INVENTORY }
    case actionTypes.CLICK_DELETE_CATEGORY:
      return { ...state, modal: modalTypes.DELETE_CATEGORY }
    case actionTypes.CATEGORY_DELETE_ATTEMPT:
    case actionTypes.CANCEL_DELETE_CATEGORY:
      return { ...state, modal: undefined }
    default:
      return state
  }
}

export const getTaxGroupItems = taxGroupsData => {
  let localTaxGroupsData = taxGroupsData
  if (!taxGroupsData) {
    const venueInfoJson = JSON.parse(getVarFromDOM('venueInfoJson'))
    localTaxGroupsData = venueInfoJson.tax_groups
  }
  const taxGroups = {}
  const taxGroupsOptions = []
  for (let i = 0; i < localTaxGroupsData.length; i += 1) {
    const taxGroup = localTaxGroupsData[i]
    taxGroups[taxGroup.id] = taxGroup.tax_name
    taxGroupsOptions.push({ name: taxGroup.tax_name, value: taxGroup.id })
  }
  return { taxGroups, taxGroupsOptions }
}

function getVarFromDOM(elementId) {
  let domVar /* : string | void */
  const element = document.getElementById(elementId)
  if (element && element instanceof HTMLTextAreaElement) {
    domVar = element.value
  } else {
    throw new TypeError(`Missing dom element with id: ${elementId}`)
  }
  return domVar
}
