// /* @flow */
import _ from 'lodash'
import * as React from 'react'
import styled, { css } from 'styled-components'
import CheckboxLine from '../../../Generic/Checkbox/CheckboxLine'

/* type Props = {
  options: { [string]: { label: string, isSelected: boolean } },
  optionClickHandler: string => void,
} */

const CategoryMultiSelectDropdown = (props /* : Props */) => (
  <CategoryDropdownSection>
    {_.map(props.options, (option, optionKey) => (
      <CheckboxLine
        key={`sr-checkbox-${optionKey}`}
        field={optionKey}
        label={option.label}
        isSelected={option.isSelected}
        onClick={props.optionClickHandler}
        customCheckboxLabel={css`
          font-size: 14px;
          color: rgba(154, 154, 154, 1);
        `}
      />
    ))}
  </CategoryDropdownSection>
)

const CategoryDropdownSection = styled.div`
  position: absolute;
  width: 100%;
  padding: 5px;
  margin-top: -1px;
  border: 1px solid rgba(218, 218, 218, 1);
  box-shadow: 0px 1px 5px -2px rgba(0, 0, 0, 0.75);
  background-color: white;
  z-index: 501;
  box-sizing: inherit;
`

export default CategoryMultiSelectDropdown
