// /* @flow */
import * as React from 'react'

const UpsellStyleTag = () => (
  <style
    dangerouslySetInnerHTML={{
      __html: `
      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #BDBDBD;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: #BDBDBD;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: #BDBDBD;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: #BDBDBD;
      }`,
    }}
  />
)

export default UpsellStyleTag
