// /* @flow */
import * as React from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import StandardButton from '../../../../component-lib/Generic/Buttons/StandardButton'
import categoryActions from '../actions/categories'
import SectionTitle from '../components/SectionTitle'
import { viewTypes } from '../constants'
/* import type { CategoryAction, SetViewMainAction } from '../flowTypes/actions' */

/* type View = $Keys<typeof viewTypes> */

/* type Props = {
  view: View,
  categoryName: string,
  categoryId: string,
  addCategory: () => CategoryAction,
  setViewToMain: ($Keys<typeof viewTypes>) => SetViewMainAction,
} */

const SectionHeader = (props /* : Props */) => {
  const titleText = props.categoryId ? props.categoryName : 'NEW CATEGORY'
  return (
    <SectionHeaderWrapper>
      <SectionTitle view={props.view} titleText={titleText} setViewToMain={props.setViewToMain} />
      {props.view === viewTypes.VIEW_CATEGORIES && (
        <StandardButton onClick={props.addCategory} customButton={ButtonStyle}>
          Add Category
        </StandardButton>
      )}
    </SectionHeaderWrapper>
  )
}

const SectionHeaderWrapper = styled.div`
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
`

const ButtonStyle = css`
  float: right;
  height: 44px;
  line-height: 44px;
  margin: 3px 15px 3px 0;
`

const mapStateToProps = state => ({
  view: state.main.view,
  categoryName: state.categories.edit.categoryName.toUpperCase(),
  categoryId: state.categories.edit.id,
})

const mapDispatchToProps = {
  addCategory: categoryActions.addCategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionHeader)
