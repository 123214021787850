import React from 'react'
import RichText from 'svr/manager/events/src/components/RichText'
import { categoryFormFields } from '../constants'

const AdvancedUpgradeDescription = props => {
  const defaultStyle = {
    padding: '1px',
    border: '1px solid #ddd',
    borderRadius: '1px',
    boxSizing: 'border-box',
    maxHeight: '14em', // Assuming 1.5em per line
    overflow: 'auto',
  }
  return (
    props.advancedPDRUpgrades && (
      <RichText
        onChange={value => props.changeCategoryField(value, categoryFormFields.ADVANCED_DESCRIPTION)}
        content={props.content}
        defaultStyle={defaultStyle}
      />
    )
  )
}

export default AdvancedUpgradeDescription
