// /* @flow */
import _ from 'lodash'
import RichTextEditor from 'react-rte'
import * as actionTypes from '../actionTypes'
import { transformModifierSelectionFromAPIToState } from '../utils/transformData'
/* import type { Action } from '../flowTypes/actions' */

/* export type InventoryEntity = {
  +id?: string,
  +categoryId?: string,
  +inventoryTypeName: string,
  +price: string,
  +description: string,
  +previewImageUrlKey?: string | null,
  +reservationTags: mixed[],
  +sortOrder: number,
  +formErrors: { [string]: boolean },
  +isLoading: boolean,
  +isPriceHidden: boolean,
} */

/* export type InventoryEntities = {
  +[string]: InventoryEntity,
} */

/* export type InventoryState = {
  +edit: InventoryEntity,
  +entities: InventoryEntities,
} */

const initialState /* : InventoryState */ = {
  edit: {
    id: undefined,
    categoryId: undefined,
    inventoryTypeName: '',
    price: '',
    description: RichTextEditor.createEmptyValue(),
    // JSON.stringify turns undefined into an empty string,
    // which get_json_args on the server does not accept
    previewImageUrlKey: null,
    sortOrder: 0,
    reservationTags: [],
    formErrors: {},
    isLoading: false,
    isPriceHidden: false,
    selectModifiersModalShow: false,
    posItemId: '',
    modifiersGroupsChecked: [],
  },
  entities: {},
  notificationBannerState: {
    message: '',
    level: 'INFO', // INFO, WARN, ERROR, SUCCESS
    visible: false,
    actionText: null,
    actionType: null,
  },
}

export default (state /* : InventoryState */ = initialState, action /* : Action */) /* : InventoryState */ => {
  switch (action.type) {
    case actionTypes.ADD_INVENTORY:
    case actionTypes.CHANGE_INVENTORY_FIELD:
    case actionTypes.VALIDATE_INVENTORY:
    case actionTypes.TOGGLE_INVENTORY_FIELD:
    case actionTypes.TOGGLE_SELECT_MODIFIERS_MODAL:
    case actionTypes.CHANGE_MODIFIERS_FIELD:
      return { ...state, edit: edit(state.edit, action) }
    case actionTypes.INVENTORY_POST_SUCCESS: {
      return {
        ...state,
        edit: edit(state.edit, action),
        entities: entities(state.entities, action),
      }
    }
    case actionTypes.COPY_INVENTORY: {
      const { inventoryTypeName } = state.entities[action.inventoryId]
      const newInventoryName = `${inventoryTypeName} (copy)`
      return {
        ...state,
        edit: {
          ...state.entities[action.inventoryId],
          id: undefined,
          inventoryTypeName: newInventoryName,
          formErrors: {},
        },
      }
    }
    case actionTypes.EDIT_INVENTORY:
      return {
        ...state,
        edit: {
          ...state.entities[action.inventoryId],
          modifiersGroupsChecked: state.entities[action.inventoryId]?.modifiersGroupsChecked?.map(item => {
            return {
                ...item,
                maxRequired: item.max_required,
                minRequired: item.min_required
            };
          }),
          formErrors: {},
        },
      }
    case actionTypes.CATEGORY_COPY_SUCCESS:
    case actionTypes.INVENTORY_DELETE_FAILURE:
    case actionTypes.INVENTORY_DELETE_ATTEMPT:
    case actionTypes.INVENTORY_DELETE_SUCCESS:
    case actionTypes.INVENTORY_PUT_SUCCESS:
    case actionTypes.REORDER_INVENTORIES_SUCCESS:
      return {
        ...state,
        entities: entities(state.entities, action),
      }
    case actionTypes.DATA_GET_SUCCESS:
      return {
        ...state,
        entities: entities(state.entities, action),
      }
    case actionTypes.SHOW_NOTIFICATION_BANNER:
      return {
        ...state,
        notificationBannerState: {
          ...action.notificationBannerState,
          visible: true,
        },
      }
    case actionTypes.CLOSE_NOTIFICATION_BANNER:
    case actionTypes.CLICK_NOTIFICATION_BANNER_ACTION:
      return {
        ...state,
        notificationBannerState: {
          ...state.notificationBannerState,
          visible: false,
        },
      }
    default:
      return state
  }
}

const edit = (state /* : InventoryEntity */, action /* : Action */) /* : InventoryEntity */ => {
  switch (action.type) {
    case actionTypes.ADD_INVENTORY:
      return {
        ...initialState.edit,
        categoryId: action.categoryId,
        sortOrder: action.sortOrder,
      }
    case actionTypes.CHANGE_MODIFIERS_FIELD:
      return {
        ...initialState.edit,
        ...state,
        modifiersGroupsChecked: action.value?.map(item => {
          return {
              ...item,
              max_required: item.maxRequired,
              min_required: item.minRequired
          };
        })
      }
    case actionTypes.CHANGE_INVENTORY_FIELD: {
      const newFormErrors = { ...state.formErrors }
      delete newFormErrors[action.field]
      return {
        ...state,
        [action.field]: action.value,
        formErrors: newFormErrors,
      }
    }
    case actionTypes.VALIDATE_INVENTORY:
      return { ...state, formErrors: action.formErrors }
    case actionTypes.INVENTORY_POST_SUCCESS:
      return { ...state, id: action.inventoryEntity.id }
    case actionTypes.TOGGLE_INVENTORY_FIELD:
      return {
        ...state,
        [action.field]: !state[action.field],
        price: action.field === 'isPriceHidden' ? (state[action.field] ? '' : '0.00') : state.price,
      }
    case actionTypes.TOGGLE_SELECT_MODIFIERS_MODAL:
      return {
        ...state,
        selectModifiersModalShow: !state.selectModifiersModalShow,
      }
    default:
      return state
  }
}

const entities = (state /* : InventoryEntities */, action /* : Action */) /* : InventoryEntities */ => {
  switch (action.type) {
    case actionTypes.INVENTORY_POST_SUCCESS:
    case actionTypes.INVENTORY_PUT_SUCCESS:
      return {
        ...state,
        [action.inventoryEntity.id]: action.inventoryEntity,
      }
    case actionTypes.DATA_GET_SUCCESS:
      return { ...state, ...(transformModifierSelectionFromAPIToState(action.payload.inventory))}
    case actionTypes.INVENTORY_DELETE_SUCCESS: {
      const newState = { ...state }
      delete newState[action.inventoryId]
      return newState
    }
    case actionTypes.INVENTORY_DELETE_ATTEMPT:
      return {
        ...state,
        [action.inventoryId]: {
          ...state[action.inventoryId],
          isLoading: true,
        },
      }
    case actionTypes.INVENTORY_DELETE_FAILURE: {
      return {
        ...state,
        [action.inventoryId]: {
          ...state[action.inventoryId],
          isLoading: false,
        },
      }
    }
    case actionTypes.CATEGORY_COPY_SUCCESS:
      return { ...state, ...action.payload.inventories }
    case actionTypes.REORDER_INVENTORIES_SUCCESS: {
      const { sortOrderMap } = action
      return _.mapValues({ ...state }, (entity, entityId) =>
        sortOrderMap[entityId] ? _.assign(entity, { sortOrder: sortOrderMap[entityId].sortOrder }) : entity
      )
    }
    default:
      return state
  }
}
