// /* @flow */
import _ from 'lodash'
import { srDelete, srPost, srPut } from '@sevenrooms/core/api'
import * as actionTypes from '../actionTypes'
import { selectNextCategorySortOrder } from '../selectors'
import * as utils from '../utils/transformData'
/* import type { Dispatch, GetState } from '../flowTypes/redux' */

const API_NAMESPACE = '/api-yoa'

export const saveCategory = () => async (dispatch /* : Dispatch */, getState /* : GetState */) => {
  const state = getState()
  const categoryEditState = state.categories.edit
  if (categoryEditState.id) {
    dispatch(categoryPutAttempt())
    const putResponse = await srPut(
      `${API_NAMESPACE}/venue/upsells`,
      utils.transformCategoryStateToPutApi(categoryEditState, state.main.venueId)
    )
    return putResponse.status === 200
      ? dispatch(categoryPutSuccess(utils.transformCategoryApiDataToState(putResponse.data.category, state.main)))
      : dispatch(categoryPutFailure())
  }
  dispatch(categoryPostAttempt())
  const postResponse = await srPost(
    `${API_NAMESPACE}/venue/upsells`,
    utils.transformCategoryStateToPostApi(categoryEditState, state.main.venueId)
  )
  return postResponse.status === 200
    ? dispatch(categoryPostSuccess(utils.transformCategoryApiDataToState(postResponse.data.category, state.main)))
    : dispatch(categoryPostFailure())
}

export const deleteCategory = (categoryId /* : string */) => async (dispatch /* : Dispatch */, getState /* : GetState */) => {
  const { venueId } = getState().main
  dispatch(categoryDeleteAttempt(categoryId))
  const deleteResponse = await srDelete(
    `${API_NAMESPACE}/venue/upsells`,
    {
      venue: venueId,
      category_id: categoryId,
    },
    { skipLoadClickBlocker: true }
  )
  return deleteResponse.status === 200 ? dispatch(categoryDeleteSuccess(categoryId)) : dispatch(categoryDeleteFailure(categoryId))
}

export const copyCategory = (categoryId /* : string */) => async (dispatch /* : Dispatch */, getState /* : GetState */) => {
  const state = getState()
  const sortOrder = selectNextCategorySortOrder(state)
  const { venueId } = state.main
  dispatch(categoryCopyAttempt())
  const copyResponse = await srPost(`${API_NAMESPACE}/venue/upsells`, {
    venue: venueId,
    category_id: categoryId,
    sort_order: sortOrder,
  })
  return copyResponse.status === 200
    ? dispatch(categoryCopySuccess(utils.transformDataFromCopyApi(copyResponse.data, state.main)))
    : dispatch(categoryCopyFailure())
}

const reorderCategories = _.debounce(async (dispatch, getState) => {
  const state = getState()
  const categoryViewOrder = state.categories.viewOrder
  const { venueId } = state.main
  dispatch(reorderCategoriesAttempt())
  const reorderResponse = await srPost(
    `/manager/${venueId}/manage/upsell_category/order/save`,
    {
      category_view_order: categoryViewOrder,
    },
    { skipLoadClickBlocker: true }
  )
  return reorderResponse.statusCode === 200
    ? dispatch(reorderCategoriesSuccess(utils.transformDataFromOrderApi(reorderResponse.payload.category_order)))
    : dispatch(reorderCategoriesFailure())
}, 2500)

export const moveCategoryUp = (categoryId /* : string */) => async (dispatch /* : Dispatch */, getState /* : GetState */) => {
  dispatch({
    type: actionTypes.MOVE_CATEGORY_UP,
    categoryId,
  })
  reorderCategories(dispatch, getState)
}

export const moveCategoryDown = (categoryId /* : string */) => async (dispatch /* : Dispatch */, getState /* : GetState */) => {
  dispatch({
    type: actionTypes.MOVE_CATEGORY_DOWN,
    categoryId,
  })
  reorderCategories(dispatch, getState)
}

// HTTP Action creators
const categoryPutAttempt = () => ({ type: actionTypes.CATEGORY_PUT_ATTEMPT })
const categoryPutSuccess = categoryEntity => ({
  type: actionTypes.CATEGORY_PUT_SUCCESS,
  categoryEntity,
})
const categoryPutFailure = () => ({ type: actionTypes.CATEGORY_PUT_FAILURE })
const categoryPostAttempt = () => ({ type: actionTypes.CATEGORY_POST_ATTEMPT })
const categoryPostSuccess = categoryEntity => ({
  type: actionTypes.CATEGORY_POST_SUCCESS,
  categoryEntity,
})
const categoryPostFailure = () => ({ type: actionTypes.CATEGORY_POST_FAILURE })
const categoryCopyAttempt = () => ({ type: actionTypes.CATEGORY_COPY_ATTEMPT })
const categoryCopySuccess = payload => ({
  type: actionTypes.CATEGORY_COPY_SUCCESS,
  payload,
})
const categoryCopyFailure = () => ({ type: actionTypes.CATEGORY_COPY_FAILURE })
const categoryDeleteAttempt = categoryId => ({
  type: actionTypes.CATEGORY_DELETE_ATTEMPT,
  categoryId,
})
const categoryDeleteSuccess = categoryId => ({
  type: actionTypes.CATEGORY_DELETE_SUCCESS,
  categoryId,
})
const categoryDeleteFailure = categoryId => ({
  type: actionTypes.CATEGORY_DELETE_FAILURE,
  categoryId,
})
const reorderCategoriesAttempt = () => ({
  type: actionTypes.REORDER_CATEGORIES_ATTEMPT,
})
const reorderCategoriesSuccess = sortOrderMap => ({
  type: actionTypes.REORDER_CATEGORIES_SUCCESS,
  sortOrderMap,
})
const reorderCategoriesFailure = () => ({
  type: actionTypes.REORDER_CATEGORIES_FAILURE,
})
