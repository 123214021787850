// /* @flow */
import _ from 'lodash'
import RichTextEditor from 'react-rte'
/* import type { CategoryEntity } from '../reducers/categories' */
/* import type { InventoryEntity } from '../reducers/inventory' */

const CATEGORY_API_TO_STATE_MAP = {
  id: 'id',
  max_quantity_int: 'maxQuantityValue',
  max_quantity_type: 'maxQuantityType',
  min_quantity_int: 'minQuantityValue',
  min_quantity_type: 'minQuantityType',
  name: 'categoryName',
  show_category_flag: 'isNameShown',
  sort_order: 'sortOrder',
  is_charging_tax: 'isChargingTax',
  is_charging_gratuity: 'isChargingGratuity',
  require_gratuity_charge: 'requireGratuityCharge',
  gratuity_charge_type: 'gratuityChargeType',
  gratuity_percentage: 'gratuityPercentage',
  tax_group_id: 'taxGroupId',
  is_charging_service_charge: 'isChargingServiceCharge',
  service_charge_type: 'serviceChargeType',
  service_charge_percentage: 'serviceChargePercentage',
  max_quantity_allow_multiples: 'maxQuantityAllowMultiples',
  advanced_pdr_upgrades: 'advancedPDRUpgrades',
  is_flexible_fees_integration_enabled: 'isFlexibleFeesIntegrationEnabled',
  pdr_fees: 'pdrFees',
  advanced_description: 'advancedDescription',
}

export const transformCategoryApiDataToState = (categoryData /* : { [string]: mixed } */, mainData /* : { [string]: mixed } */) => {
  const defaultCategoryEntity = { isLoading: false, inventory: [] }
  const categoryStates = _.reduce(
    categoryData,
    (result, value, key) => {
      const stateKey = CATEGORY_API_TO_STATE_MAP[key]
      return stateKey ? { ...result, [stateKey]: value } : { ...result }
    },
    defaultCategoryEntity
  )
  let taxGroupSelected = []
  if (categoryStates.taxGroupId) {
    if (categoryStates.taxGroupId in mainData.taxGroups) {
      taxGroupSelected = [
        {
          name: mainData.taxGroups[categoryStates.taxGroupId],
          value: categoryStates.taxGroupId,
        },
      ]
    }
  }
  categoryStates.taxGroupSelected = taxGroupSelected
  return categoryStates
}

export const transformCategoryStateToPostApi = (categoryEditState /* : CategoryEntity */, venueId /* : string */) => ({
  venue: venueId,
  name: categoryEditState.categoryName,
  show_category_flag: categoryEditState.isNameShown,
  max_quantity_type: categoryEditState.maxQuantityType,
  max_quantity_int: categoryEditState.maxQuantityValue,
  min_quantity_type: categoryEditState.minQuantityType,
  min_quantity_int: categoryEditState.minQuantityValue,
  sort_order: categoryEditState.sortOrder,
  is_charging_tax: categoryEditState.taxGroupId ? categoryEditState.isChargingTax : false,
  is_charging_gratuity: categoryEditState.isChargingGratuity,
  require_gratuity_charge:
    categoryEditState.isChargingGratuity && categoryEditState.gratuityChargeType === 'CLIENT_GRATUITY'
      ? categoryEditState.requireGratuityCharge
      : false,
  gratuity_charge_type: categoryEditState.gratuityChargeType,
  gratuity_percentage:
    categoryEditState.isChargingGratuity && categoryEditState.gratuityChargeType === 'SPECIFIC_GRATUITY'
      ? categoryEditState.gratuityPercentage
      : null,
  tax_group_id: categoryEditState.taxGroupId,
  is_charging_service_charge: categoryEditState.isChargingServiceCharge,
  service_charge_type: categoryEditState.serviceChargeType,
  service_charge_percentage:
    categoryEditState.isChargingServiceCharge && categoryEditState.serviceChargeType === 'SPECIFIC_SERVICE_CHARGE'
      ? categoryEditState.serviceChargePercentage
      : null,
  max_quantity_allow_multiples: categoryEditState.maxQuantityAllowMultiples,
  advanced_pdr_upgrades: categoryEditState.advancedPDRUpgrades,
  advanced_description: categoryEditState.advancedDescription,
  pdr_fees: JSON.stringify(categoryEditState.pdrFees),
})

export const transformCategoryStateToPutApi = (categoryEditState /* : CategoryEntity */, venueId /* : string */) => ({
  venue: venueId,
  category_id: categoryEditState.id,
  name: categoryEditState.categoryName,
  show_category_flag: categoryEditState.isNameShown,
  max_quantity_type: categoryEditState.maxQuantityType,
  max_quantity_int: categoryEditState.maxQuantityValue,
  min_quantity_type: categoryEditState.minQuantityType,
  min_quantity_int: categoryEditState.minQuantityValue,
  sort_order: categoryEditState.sortOrder,
  is_charging_tax: categoryEditState.taxGroupId ? categoryEditState.isChargingTax : false,
  is_charging_gratuity: categoryEditState.isChargingGratuity,
  require_gratuity_charge:
    categoryEditState.isChargingGratuity && categoryEditState.gratuityChargeType === 'CLIENT_GRATUITY'
      ? categoryEditState.requireGratuityCharge
      : false,
  gratuity_charge_type: categoryEditState.gratuityChargeType,
  gratuity_percentage:
    categoryEditState.isChargingGratuity && categoryEditState.gratuityChargeType === 'SPECIFIC_GRATUITY'
      ? categoryEditState.gratuityPercentage
      : null,
  tax_group_id: categoryEditState.taxGroupId,
  is_charging_service_charge: categoryEditState.isChargingServiceCharge,
  service_charge_type: categoryEditState.serviceChargeType,
  service_charge_percentage:
    categoryEditState.isChargingServiceCharge && categoryEditState.serviceChargeType === 'SPECIFIC_SERVICE_CHARGE'
      ? categoryEditState.serviceChargePercentage
      : null,
  max_quantity_allow_multiples: categoryEditState.maxQuantityAllowMultiples,
  advanced_pdr_upgrades: categoryEditState.advancedPDRUpgrades,
  advanced_description: categoryEditState.advancedDescription,
  pdr_fees: JSON.stringify(categoryEditState.pdrFees),
})

const INVENTORY_API_TO_STATE_MAP = {
  id: 'id',
  category_id: 'categoryId',
  description: 'description',
  name: 'inventoryTypeName',
  price: 'price',
  preview_image_url_key: 'previewImageUrlKey',
  sort_order: 'sortOrder',
  reservation_tags: 'reservationTags',
  is_price_hidden: 'isPriceHidden',
  pos_item_id: 'posItemId',
  modifiers_groups_selected: 'modifiersGroupsChecked',
}

const createTagObjects = (tags /* : string[] */, tagGroups /* : mixed[] */) => {
  const createTagObjectFromHash = (tagHash /* : string */) => {
    const [, tagGroupId, , tagName] = tagHash.split('##')
    const tagGroup = _.find(tagGroups, tagGroup => tagGroup.id === tagGroupId)
    return {
      tagName,
      tagHash,
      tagGroup,
    }
  }
  return _.map(tags, createTagObjectFromHash)
}

export const transformInventoryApiDataToState = (inventoryData /* : { [string]: mixed } */, reservationTags /* : mixed[] */) => {
  const defaultInventoryEntity = { isLoading: false }
  return _.reduce(
    inventoryData,
    (result, value, key) => {
      const stateKey = INVENTORY_API_TO_STATE_MAP[key]
      let stateValue = value
      if (stateKey === 'description') {
        stateValue = RichTextEditor.createValueFromString(value, 'html')
      } else if (stateKey === 'price') {
        stateValue = value.toFixed(2)
      } else if (stateKey === 'reservationTags') {
        stateValue = createTagObjects(value, reservationTags)
      }
      return stateKey ? { ...result, [stateKey]: stateValue } : { ...result }
    },
    defaultInventoryEntity
  )
}

// transforms description links to open in new window
// unfortunate hack needed for 3p lib
const transformRichTextToString = editorState =>
  // $FlowFixMe
  editorState.toString('html').replace(/<a /g, '<a target="_blank" ')

export const transformInventoryStateToPostApi = (inventoryEditState /* : InventoryEntity */, venueId /* : string */) => ({
  venue: venueId,
  category_id: inventoryEditState.categoryId,
  description: transformRichTextToString(inventoryEditState.description),
  price: inventoryEditState.price,
  name: inventoryEditState.inventoryTypeName,
  preview_image_url_key: inventoryEditState.previewImageUrlKey,
  sort_order: inventoryEditState.sortOrder,
  reservation_tags: _.map(inventoryEditState.reservationTags, 'tagHash'),
  is_price_hidden: inventoryEditState.isPriceHidden,
  pos_item_id: inventoryEditState.posItemId,
  modifiers_groups_selected: (
    inventoryEditState.modifiersGroupsChecked ? (
      inventoryEditState.modifiersGroupsChecked.map(modifier => (
        {
          ...modifier,
          min_required: modifier.minRequired,
          max_required: modifier.maxRequired
        }
      ))
    ) : null),
})

export const transformInventoryStateToPutApi = (inventoryEditState /* : InventoryEntity */, venueId /* : string */) => ({
  venue: venueId,
  category_id: inventoryEditState.categoryId,
  inventory_id: inventoryEditState.id,
  description: transformRichTextToString(inventoryEditState.description),
  price: inventoryEditState.price,
  name: inventoryEditState.inventoryTypeName,
  preview_image_url_key: inventoryEditState.previewImageUrlKey || '',
  sort_order: inventoryEditState.sortOrder,
  reservation_tags: _.map(inventoryEditState.reservationTags, 'tagHash'),
  is_price_hidden: inventoryEditState.isPriceHidden,
  pos_item_id: inventoryEditState.posItemId,
  modifiers_groups_selected: (
    inventoryEditState.modifiersGroupsChecked ? (
      inventoryEditState.modifiersGroupsChecked.map(modifier => (
        {
          ...modifier,
          min_required: modifier.minRequired,
          max_required: modifier.maxRequired
        }
      ))
    ) : null),
})

/* type GetPayload = {
  categories: Array<{ [string]: mixed }>,
  inventory: Array<{ [string]: mixed }>,
} */

export const transformModifierSelectionFromAPIToState = (inventory) => {
  for (const key in inventory) {
    if (inventory[key].modifiersGroupsChecked) {
      inventory[key].modifiersGroupsChecked = inventory[key].modifiersGroupsChecked?.map(group => {
        return {
          ...group,
          minRequired: group.min_required,
          maxRequired: group.max_required
        };
      });
    }
  }
  return inventory;
}
export const transformDataFromGetApi = (payload /* : GetPayload */, mainData) => {
  const categoriesPayload = _.reduce(
    payload.categories,
    (accum, entity) => ({
      ...accum,
      [entity.id]: transformCategoryApiDataToState(entity, mainData),
    }),
    {}
  )

  const inventoryPayload = _.reduce(
    payload.inventory,
    (accum, entity) => ({
      ...accum,
      [entity.id]: transformInventoryApiDataToState(entity, mainData.reservationTags),
    }),
    {}
  )

  // get the order in which the categories are displayed
  const categoryKeys = _.keys(categoriesPayload)
  const categoriesViewOrder = _.sortBy(categoryKeys, key => categoriesPayload[key].sortOrder)

  // within category entities, build inventory array (sorted by sortOrder)
  _.each(inventoryPayload, (inventoryEntity, inventoryId) => {
    const categoryEntity = categoriesPayload[inventoryEntity.categoryId]
    if (categoryEntity) {
      if (!categoryEntity.inventory) {
        categoryEntity.inventory = []
      }
      categoryEntity.inventory.push(inventoryId)
    }
  })
  const categories = _.mapValues(categoriesPayload, category => ({
    ...category,
    inventory: category.inventory.sort((a, b) => inventoryPayload[a].sortOrder - inventoryPayload[b].sortOrder),
  }))
  return {
    categories,
    inventory: inventoryPayload,
    categoriesViewOrder,
  }
}

/* type CopyPayload = {
  category: { [string]: mixed },
  inventories: Array<{ [string]: mixed }>,
} */

export const transformDataFromCopyApi = (payload /* : CopyPayload */, mainData) => {
  const inventoryPayload = _.reduce(
    payload.inventories,
    (accum, entity) => ({
      ...accum,
      [entity.id]: transformInventoryApiDataToState(entity, mainData.reservationTags),
    }),
    {}
  )

  const categoryPayload = transformCategoryApiDataToState(payload.category, mainData)
  categoryPayload.inventory = _.sortBy(_.keys(inventoryPayload), inventoryId => inventoryPayload[inventoryId].sortOrder)

  return {
    category: categoryPayload,
    inventories: inventoryPayload,
  }
}

export const transformDataFromOrderApi = (categoriesViewOrder /* : Array<string> */) =>
  _.reduce(categoriesViewOrder, (result, categoryId, index) => _.assign(result, { [categoryId]: { sortOrder: index } }), {})
