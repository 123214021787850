// /* @flow */
import * as React from 'react'

/* type Props = { tagId: string } */

// TODO: This is needed because the styles on our manager site override those of styled-components
// hopefully this can be removed soon
const InventoryListStyleTag = (props /* : Props */) => (
  <style
    dangerouslySetInnerHTML={{
      __html: `#${props.tagId} ol, #${props.tagId} ul {
        display: block; list-style-type: decimal;
        -webkit-margin-before: 1em;
        -webkit-margin-after: 1em;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
        -webkit-padding-start: 40px;
      }
      
      #${props.tagId} p {
        display: block;
        -webkit-margin-before: 1em; 
        -webkit-margin-after: 1em; 
        -webkit-margin-start: 0px; 
        -webkit-margin-end: 0px;
      }`,
    }}
  />
)

export default InventoryListStyleTag
