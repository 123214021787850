import { z } from '@sevenrooms/core/form'

export const MinMaxSchema = z.object({
  minAmount: z.number().nullable().default(null),
  maxAmount: z.number().nullable().default(null),
  group: z.string().nullable().default(null),
})

export const ModifierMinMaxSchema = z
  .object({
    minMax: z.array(MinMaxSchema).default([]),
  })
  .superRefine(() => {})
