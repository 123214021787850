// /* @flow */
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import categoryActions from '../actions/categories'
import * as categoriesThunks from '../actions/categoriesThunks'
import CategoryLineItem from '../components/CategoryLineItem'
import { selectCategoryViewOrder } from '../selectors'
/* import type { CategoryEntities } from '../reducers/categories' */
/* import type { InventoryEntities } from '../reducers/inventory' */
/* import type { CategoryAction } from '../flowTypes/actions' */

/* type Props = {
  mediaUrl: string,
  currencySymbol: string,
  categoryEntities: CategoryEntities,
  inventoryEntities: InventoryEntities,
  categoryViewOrder: Array<string>,
  copyCategory: string => CategoryAction,
  editCategory: string => CategoryAction,
  clickDeleteCategory: string => CategoryAction,
  moveCategoryUp: string => CategoryAction,
  moveCategoryDown: string => CategoryAction,
} */

class ViewCategoriesSection extends React.PureComponent /* <Props> */ {
  render() {
    return (
      <SectionWrapper>
        {this.props.categoryViewOrder.map(entityId => {
          const entity = this.props.categoryEntities[entityId]
          const categoryInventory = entity.inventory.map(inventoryId => this.props.inventoryEntities[inventoryId])
          const sortedCategoryInventory = categoryInventory.sort((a, b) => b.sortOrder - a.sortOrder)
          return (
            <CategoryLineItem
              key={`sr-category-${entityId}`}
              categoryId={entityId}
              mediaUrl={this.props.mediaUrl}
              currencySymbol={this.props.currencySymbol}
              categoryName={entity.categoryName}
              minQuantityType={entity.minQuantityType}
              maxQuantityType={entity.maxQuantityType}
              maxQuantityValue={entity.maxQuantityValue}
              isLoading={entity.isLoading}
              categoryInventory={sortedCategoryInventory}
              copyCategory={this.props.copyCategory}
              editCategory={this.props.editCategory}
              deleteCategory={this.props.clickDeleteCategory}
              moveCategoryUp={this.props.moveCategoryUp}
              moveCategoryDown={this.props.moveCategoryDown}
            />
          )
        })}
      </SectionWrapper>
    )
  }
}

const SectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`

const mapStateToProps = state => ({
  mediaUrl: state.main.mediaUrl,
  currencySymbol: state.main.currencySymbol,
  categoryEntities: state.categories.entities,
  inventoryEntities: state.inventory.entities,
  categoryViewOrder: selectCategoryViewOrder(state),
})

const mapDispatchToProps = {
  copyCategory: categoriesThunks.copyCategory,
  editCategory: categoryActions.editCategory,
  clickDeleteCategory: categoryActions.clickDeleteCategory,
  moveCategoryUp: categoriesThunks.moveCategoryUp,
  moveCategoryDown: categoriesThunks.moveCategoryDown,
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewCategoriesSection)
