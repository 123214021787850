// /* @flow */
import _ from 'lodash'
import * as React from 'react'
import styled from 'styled-components'

/* type Props = {
  options: { [string]: { label: string, isSelected: boolean } },
  onClick: () => void,
} */

const CategoryMultiSelectInput = (props /* : Props */) => (
  <MultiSelectInputWrapper onClick={props.onClick}>
    {_.reduce(
      props.options,
      (result, option, optionKey) =>
        option.isSelected
          ? result.concat(<MultiSelectOptionLabel key={`sr-option-${optionKey}`}>{option.label}</MultiSelectOptionLabel>)
          : result,
      []
    )}
  </MultiSelectInputWrapper>
)

const MultiSelectInputWrapper = styled.div`
  width: 100%;
  min-height: 35px;
  padding: 4px;
  border: 1px solid rgba(218, 218, 218, 1);
  background-color: white;
  border-radius: 3px;
  cursor: pointer;
  font-family: Roboto;
  box-sizing: inherit;
`

const MultiSelectOptionLabel = styled.div`
  display: inline-block;
  height: 23px;
  line-height: 23px;
  font-size: 14px;
  padding: 0px 5px;
  margin: 1px 3px;
  border: 1px solid rgba(141, 141, 141, 1);
  border-radius: 3px;
  color: rgba(141, 141, 141, 1);
  background-color: rgba(241, 241, 241, 1);
  box-sizing: inherit;
`

export default CategoryMultiSelectInput
