// /* @flow */
import * as React from 'react'
import styled from 'styled-components'

/* type Props = {
  onClick: () => mixed,
  children: React.Node,
  customButton: Array<string | (() => mixed)>,
  customAddIcon: Array<string | (() => mixed)>,
} */

const AddButton = (props /* : Props */) => (
  <AddButtonWrapper onClick={props.onClick} customButton={props.customButton}>
    <AddIcon customAddIcon={props.customAddIcon}>+</AddIcon>
    {props.children}
  </AddButtonWrapper>
)

AddButton.defaultProps = {
  customButton: [],
  customAddIcon: [],
}

const AddButtonWrapper = styled.button`
  height: 45px;
  line-height: 45px;
  padding: 0 20px;
  border: 1px solid rgba(120, 156, 239, 1);
  border-radius: 5px;
  outline: none;
  font-weight: 200;
  font-size: 15px;
  font-family: Roboto;
  text-align: center;
  background-color: white;
  color: rgba(120, 156, 239, 1);
  box-sizing: border-box;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 1;
  }
  ${props => props.customButton};
`

const AddIcon = styled.span`
  background-color: rgba(120, 156, 239, 1);
  border-radius: 10px;
  color: rgb(255, 255, 255);
  display: inline-block;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  margin-right: 8px;
  text-align: center;
  width: 16px;
  box-sizing: inherit;
`

export default AddButton
