// /* @flow */
import * as React from 'react'
import styled, { css } from 'styled-components'
import UtilButton from './UtilButton'
/* import type { InventoryAction } from '../flowTypes/actions' */
/* import type { PromiseAction } from '../flowTypes/redux' */

/* type Props = {
  inventoryTypeName: string,
  price: string,
  mediaUrl: string,
  currencySymbol: string,
  description: string,
  inventoryId: string,
  categoryId: string,
  isLoading: boolean,
  copyInventory: string => InventoryAction,
  editInventory: string => InventoryAction,
  deleteInventory: (string, string) => PromiseAction,
  moveInventoryUp: string => InventoryAction,
  moveInventoryDown: string => InventoryAction,
  isPriceHidden: boolean,
} */

class InventoryListItem extends React.PureComponent /* <Props> */ {
  copyInventory = this.props.copyInventory.bind(this, this.props.inventoryId)

  editInventory = this.props.editInventory.bind(this, this.props.inventoryId)

  deleteInventory = this.props.deleteInventory.bind(this, this.props.inventoryId, this.props.categoryId)

  moveInventoryUp = this.props.moveInventoryUp.bind(this, this.props.inventoryId)

  moveInventoryDown = this.props.moveInventoryDown.bind(this, this.props.inventoryId)

  render() {
    const showPrice = !this.props.isPriceHidden && parseInt(this.props.price) >= 0
    return (
      <InventoryItemWrapper isLoading={this.props.isLoading}>
        <InventoryNameText>{this.props.inventoryTypeName}</InventoryNameText>
        <InventoryPriceText>{showPrice && `${this.props.currencySymbol}${this.props.price}`}</InventoryPriceText>
        {!this.props.isLoading && (
          <ButtonPanel>
            <UtilButton
              onClick={this.copyInventory}
              tooltip="Clone upgrade"
              imgSrc={`${this.props.mediaUrl}images/eventmanager/clone.png`}
            />
            <UtilButton onClick={this.editInventory} tooltip="Edit upgrade" imgSrc={`${this.props.mediaUrl}images/eventmanager/edit.png`} />
            <UtilButton
              onClick={this.deleteInventory}
              tooltip="Delete upgrade"
              imgSrc={`${this.props.mediaUrl}images/eventmanager/delete.png`}
            />
            <UtilButton
              onClick={this.moveInventoryDown}
              tooltip="Move down"
              imgSrc={`${this.props.mediaUrl}images/icons/priority-down-button.png`}
              customButton={css`
                background-size: 33px 37px;
              `}
            />
            <UtilButton
              onClick={this.moveInventoryUp}
              tooltip="Move up"
              imgSrc={`${this.props.mediaUrl}images/icons/priority-up-button.png`}
              customButton={css`
                background-size: 33px 37px;
              `}
            />
          </ButtonPanel>
        )}
        <InventoryItemDescription dangerouslySetInnerHTML={{ __html: this.props.description }} />
      </InventoryItemWrapper>
    )
  }
}

const InventoryItemWrapper = styled.div`
  position: relative;
  width: 700px;
  padding: 13px 8px 12px;
  border: 1px solid rgba(218, 218, 218, 1);
  border-radius: 3px;
  margin: 5px 5px 5px 0;
  font-size: 16px;
  font-family: Roboto;
  box-sizing: border-box;
  opacity: ${props => (props.isLoading ? '0.5' : '1.0')};
`

const InventoryNameText = styled.span`
  float: left;
  height: 30px;
  line-height: 30px;
  font-weight: 400;
  width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
`

const InventoryPriceText = styled.span`
  display: block;
  width: 60%;
  text-align: right;
  height: 30px;
  line-height: 30px;
  font-weight: 300;
  color: rgba(153, 153, 153, 1);
`

const ButtonPanel = styled.div`
  position: absolute;
  right: 20px;
  top: 13px;
  height: 37px;
`

const InventoryItemDescription = styled.div`
  color: rgba(163, 163, 163, 1);
  font-size: 12px;
  margin-top: 12px;
`

export default InventoryListItem
