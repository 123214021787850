// /* @flow */
import { combineReducers } from 'redux'
import categories from './reducers/categories'
import inventory from './reducers/inventory'
import main from './reducers/main'
import { appStateReducer } from 'svr/manager/lib/reducers/UtilReducers'

export default combineReducers({
  appState: appStateReducer,
  main,
  categories,
  inventory,
})
