// /* @flow */
import * as React from 'react'
import { css } from 'styled-components'
import RadioList from '../../../../component-lib/Generic/RadioButtons/RadioList'
import PositiveIntInput from '../../../../component-lib/Generic/TextInputs/PositiveIntInput'
import { minQuantityTypes } from '../constants'

/* type Props = {
  minQuantityType: string,
  minQuantityTypeField: string,
  minQuantityValue: number,
  minQuantityValueField: string,
  changeCategoryField: (value: string | number, field: string) => mixed,
} */

const MinQuantityRadioList = (props /* : Props */) => (
  <RadioList
    field={props.minQuantityTypeField}
    selectedValue={props.minQuantityType}
    onClick={props.changeCategoryField}
    listLabel="Minimum quantity required per purchase"
    customRadioListLabel={css`
      color: ${props => props.theme.fontLabelColor};
      font-weight: 400;
      margin-bottom: 5px;
    `}
    customRadioLabel={css`
      color: rgba(145, 145, 145, 1);
      font-weight: 300;
    `}
    customRadioWrapper={css`
      height: auto;
    `}
    customRadioListWrapper={css`
      height: 155px;
      margin-top: 18px;
    `}
    options={[
      {
        value: minQuantityTypes.NONE_REQUIRED,
        label: 'None required',
      },
      {
        value: minQuantityTypes.NUMBER_OF_GUESTS,
        label: 'Restricted to number of guests in party',
      },
      {
        value: minQuantityTypes.SPECIFIC_NUMBER,
        label: 'Specific number',
        child: (
          <PositiveIntInput
            field={props.minQuantityValueField}
            value={props.minQuantityValue}
            onChange={props.changeCategoryField}
            charLimit={3}
            customInput={css`
              text-align: center;
            `}
            customInputWrapper={css`
              width: 60px;
              margin: 5px 10px 8px;
            `}
          />
        ),
      },
    ]}
  />
)

export default MinQuantityRadioList
