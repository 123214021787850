// /* @flow */
import * as React from 'react'
import styled from 'styled-components'

/* type Props = {
  src: string,
  onDelete: () => void,
} */

const UpgradeImage = (props /* : Props */) => (
  <Wrapper>
    <Image src={props.src} />
    <Actions>
      <DeleteButton onClick={props.onDelete} />
    </Actions>
  </Wrapper>
)

const WIDTH = 260
const HEIGHT = 194

const Wrapper = styled.div`
  position: relative;
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
`

const Image = styled.img.attrs(props => ({
  src: props.src,
}))`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  object-fit: cover;
`
const Actions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

// styles copied from application/site/static/app/manager/events/src/assets/styles/buttons.jsx
// this should be standardized
const DeleteButton = styled.div`
  background-image: url(${window.globalInit.mediaUrl}images/eventmanager/delete.png);
  background-color: #f1f1f1;
  background-size: 15px 15px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  height: 35px;
  margin-left: 5px;
  margin-top: 2px;
  width: 31px;
`

export default UpgradeImage
