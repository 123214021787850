// /* @flow */
import * as React from 'react'
import styled from 'styled-components'

/* type Props = {
  inventoryTypeName: string,
  price: string,
  description: string,
  currencySymbol: string,
  isPriceHidden: boolean,
} */

const CategoryInventoryItem = (props /* : Props */) => {
  const showPrice = !props.isPriceHidden && parseInt(props.price) >= 0
  return (
    <CategoryInventoryItemWrapper>
      <CategoryInventoryTopWrapper>
        <CategoryInventoryNameText>{props.inventoryTypeName}</CategoryInventoryNameText>
        {showPrice && <CategoryInventoryPriceText>{`${props.currencySymbol} ${props.price}`}</CategoryInventoryPriceText>}
      </CategoryInventoryTopWrapper>
      <CategoryInventoryDescriptionText dangerouslySetInnerHTML={{ __html: props.description }} />
    </CategoryInventoryItemWrapper>
  )
}

const CategoryInventoryItemWrapper = styled.div`
  height: 43px;
  margin: 8px 0 8px 35px;
  font-family: Roboto;
`
const CategoryInventoryNameText = styled.span`
  font-size: 15px;
  line-height: 15px;
`
const CategoryInventoryPriceText = styled.span`
  width: 40%;
  font-size: 15px;
  line-height: 17px;
  font-weight: 100;
  color: rgba(135, 135, 135, 1);
`
const CategoryInventoryDescriptionText = styled.div`
  font-size: 14px;
  line-height: 14px;
  margin-top: 5px;
  width: 40%;
  height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(162, 162, 162, 1);
  font-weight: 200;
`

const CategoryInventoryTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export default CategoryInventoryItem
