// /* @flow */
import * as React from 'react'
import styled, { css } from 'styled-components'
import StandardButton from '../../../../component-lib/Generic/Buttons/StandardButton'

/* type Props = {
  primaryButtonText: string,
  secondaryButtonText: string,
  onPrimaryButtonClick: () => mixed,
  onSecondaryButtonClick: () => mixed,
  customModalFooterWrapper?: Array<string | (() => mixed)>,
} */

const ModalFooter = (props /* : Props */) => (
  <ModalFooterWrapper customModalFooterWrapper={props.customModalFooterWrapper} data-test="sr-modal-footer">
    <StandardButton onClick={props.onPrimaryButtonClick} customButton={ButtonPrimaryStyle}>
      {props.primaryButtonText}
    </StandardButton>
    <StandardButton onClick={props.onSecondaryButtonClick} customButton={ButtonSecondaryStyle}>
      {props.secondaryButtonText}
    </StandardButton>
  </ModalFooterWrapper>
)

const ModalFooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  height: 60px;
  width: 100%;
  border-top: 1px solid rgba(218, 218, 218, 1);
  ${props => props.customModalFooterWrapper};
`

const ButtonSecondaryStyle = css`
  float: right;
  height: 44px;
  line-height: 44px;
  margin: 8px 15px 8px 0;
  background-color: rgba(235, 235, 235, 1);
  color: rgba(134, 134, 140, 1);
`

const ButtonPrimaryStyle = css`
  float: right;
  height: 44px;
  line-height: 44px;
  margin: 8px 15px 8px 0;
  color: white;
  background-color: rgba(120, 156, 239, 1);
`

export default ModalFooter
