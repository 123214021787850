// /* @flow */
import * as React from 'react'
import ReactTooltip from 'react-tooltip'
import styled, { css } from 'styled-components'
import { minQuantityTypes, maxQuantityTypes } from '../constants'
import CategoryInventoryItem from './CategoryInventoryItem'
import UtilButton from './UtilButton'
/* import type { CategoryAction } from '../flowTypes/actions' */

/* type Props = {
  categoryId: string,
  categoryName: string,
  mediaUrl: string,
  currencySymbol: string,
  minQuantityType: boolean,
  maxQuantityType: $Keys<typeof maxQuantityTypes>,
  maxQuantityValue: number,
  isLoading: boolean,
  categoryInventory: Array<{ [string]: mixed }>,
  copyCategory: string => CategoryAction,
  editCategory: string => CategoryAction,
  deleteCategory: string => CategoryAction,
  moveCategoryUp: string => CategoryAction,
  moveCategoryDown: string => CategoryAction,
} */

class CategoryLineItem extends React.PureComponent /* <Props> */ {
  copyCategory = this.props.copyCategory.bind(this, this.props.categoryId)

  editCategory = this.props.editCategory.bind(this, this.props.categoryId)

  deleteCategory = this.props.deleteCategory.bind(this, this.props.categoryId)

  moveCategoryUp = this.props.moveCategoryUp.bind(this, this.props.categoryId)

  moveCategoryDown = this.props.moveCategoryDown.bind(this, this.props.categoryId)

  render() {
    return (
      <CategoryLineWrapper>
        <ReactTooltip effect="solid" />
        <CategoryItemWrapper isLoading={this.props.isLoading}>
          <CategoryNameText>{this.props.categoryName}</CategoryNameText>
          <CategoryRequiredText>
            {this.props.minQuantityType !== minQuantityTypes.NONE_REQUIRED ? '- Required' : '- Optional'}
          </CategoryRequiredText>
          <CategorySubLabel>{getSubLabel(this.props.maxQuantityType, this.props.maxQuantityValue)}</CategorySubLabel>
          {!this.props.isLoading && (
            <ButtonPanel>
              <UtilButton
                onClick={this.copyCategory}
                tooltip="Clone category"
                imgSrc={`${this.props.mediaUrl}images/eventmanager/clone.png`}
              />
              <UtilButton
                onClick={this.editCategory}
                tooltip="Edit category"
                imgSrc={`${this.props.mediaUrl}images/eventmanager/edit.png`}
              />
              <UtilButton
                onClick={this.deleteCategory}
                tooltip="Delete category"
                imgSrc={`${this.props.mediaUrl}images/eventmanager/delete.png`}
              />
              <UtilButton
                onClick={this.moveCategoryDown}
                tooltip="Move down"
                imgSrc={`${this.props.mediaUrl}images/icons/priority-down-button.png`}
                customButton={css`
                  background-size: 33px 37px;
                `}
              />
              <UtilButton
                onClick={this.moveCategoryUp}
                tooltip="Move up"
                imgSrc={`${this.props.mediaUrl}images/icons/priority-up-button.png`}
                customButton={css`
                  background-size: 33px 37px;
                `}
              />
            </ButtonPanel>
          )}
        </CategoryItemWrapper>
        <CategoryInventoryWrapper isLoading={this.props.isLoading}>
          {this.props.categoryInventory.map(inventoryEntity => (
            <CategoryInventoryItem
              key={inventoryEntity.id}
              inventoryTypeName={inventoryEntity.inventoryTypeName}
              price={inventoryEntity.price}
              description={inventoryEntity.description.toString('html')}
              currencySymbol={this.props.currencySymbol}
              isPriceHidden={inventoryEntity.isPriceHidden}
            />
          ))}
        </CategoryInventoryWrapper>
      </CategoryLineWrapper>
    )
  }
}

function getSubLabel(maxQuantityType /* : $Keys<typeof maxQuantityTypes> */, maxQuantityValue /* : number */) {
  switch (maxQuantityType) {
    case maxQuantityTypes.UNLIMITED:
      return 'Unlimited quantity per purchase'
    case maxQuantityTypes.NUMBER_OF_GUESTS:
      return 'Quantity per purchase is restricted by number of guests in party'
    case maxQuantityTypes.SPECIFIC_NUMBER:
      return `Quantity per purchase is restricted to ${maxQuantityValue}`
    default:
      return ''
  }
}

const CategoryLineWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 15px 20px 10px;
  font-size: 16px;
  font-family: Roboto;
  border-bottom: 1px solid #e1e1e1;
  box-sizing: inherit;
`

const CategoryItemWrapper = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
  opacity: ${props => (props.isLoading ? '0.5' : '1.0')};
`

const CategoryNameText = styled.span`
  float: left;
  height: 30px;
  line-height: 30px;
  font-weight: 400;
`

const CategoryRequiredText = styled.span`
  float: left;
  margin-left: 5px;
  height: 30px;
  line-height: 30px;
  font-weight: 300;
  color: rgba(153, 153, 153, 1);
`

const CategorySubLabel = styled.span`
  float: left;
  clear: both;
  font-size: 12px;
  height: 15px;
  line-height: 15px;
  font-weight: 300;
  font-style: italic;
  color: rgba(174, 174, 174, 1);
`

const ButtonPanel = styled.div`
  width: 100%;
  text-align: right;
`

const CategoryInventoryWrapper = styled.div`
  opacity: ${props => (props.isLoading ? '0.5' : '1.0')};
`

export default CategoryLineItem
