// /* @flow */
import * as React from 'react'
import styled from 'styled-components'

/* type Props = {
  clickCloseModal: void => mixed,
  headerText: string,
} */

const ModalHeader = (props /* : Props */) => (
  <ModalHeaderWrapper>
    <HeaderText>{props.headerText}</HeaderText>
    <CloseModalButton onClick={props.clickCloseModal}>×</CloseModalButton>
  </ModalHeaderWrapper>
)

const ModalHeaderWrapper = styled.div`
  height: 40px;
  border-bottom: 1px solid rgba(218, 218, 218, 1);
`

const HeaderText = styled.div`
  display: inline-block;
  font-family: Oswald, sans-serif;
  float: left;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  font-weight: 300;
  color: black;
  margin-left: 10px;
`

const CloseModalButton = styled.div`
  cursor: pointer;
  font-size: 41px;
  font-weight: 100;
  position: absolute;
  right: 10px;
  top: 0px;
  height: 40px;
  line-height: 40px;
`

export default ModalHeader
