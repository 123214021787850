// /* @flow */

const cssText = `
  input[type='number']::-webkit-inner-spin-button, 
  input[type='number']::-webkit-outer-spin-button { 
    opacity: 1;
  }
`
const style = document.createElement('style')
style.type = 'text/css'

if (style.styleSheet) {
  style.styleSheet.cssText = cssText
} else {
  style.appendChild(document.createTextNode(cssText))
}

document.getElementsByTagName('head')[0].appendChild(style)
