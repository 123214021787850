// /* @flow */
import _ from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import AddButton from '../../../../component-lib/Manager/Buttons/AddButton'
import { copyInventory, editInventory } from '../actions/inventory'
import { deleteInventory, moveInventoryUp, moveInventoryDown } from '../actions/inventoryThunks'
import InventoryListItem from '../components/InventoryListItem'
import InventoryListStyleTag from '../components/InventoryListStyleTag'
import { selectCategoryInventory } from '../selectors'
/* import type { InventoryAction } from '../flowTypes/actions' */
/* import type { PromiseAction } from '../flowTypes/redux' */

/* type Props = {
  handleAddInventoryClick: () => void,
  categoryId: string,
  inventory: Array<{ [string]: mixed }>,
  mediaUrl: string,
  currencySymbol: string,
  copyInventory: string => InventoryAction,
  editInventory: string => InventoryAction,
  deleteInventory: string => PromiseAction,
  moveInventoryUp: string => InventoryAction,
  moveInventoryDown: string => InventoryAction,
} */

class InventoryTypesList extends React.PureComponent /* <Props> */ {
  // TODO: remove this once component styling can trump manager css
  inventoryTempStyleId = 'sr-inventory-temp-style-id'

  render() {
    return (
      <div id={this.inventoryTempStyleId}>
        <InventoryListStyleTag tagId={this.inventoryTempStyleId} />
        <InventoryTypesListLabel>Upgrades</InventoryTypesListLabel>
        <div>
          {_.map(this.props.inventory, inventoryEntity => (
            <InventoryListItem
              key={inventoryEntity.id}
              inventoryId={inventoryEntity.id}
              categoryId={this.props.categoryId}
              inventoryTypeName={inventoryEntity.inventoryTypeName}
              price={inventoryEntity.price}
              mediaUrl={this.props.mediaUrl}
              currencySymbol={this.props.currencySymbol}
              description={inventoryEntity.description.toString('html')}
              isLoading={inventoryEntity.isLoading}
              copyInventory={this.props.copyInventory}
              editInventory={this.props.editInventory}
              deleteInventory={this.props.deleteInventory}
              moveInventoryUp={this.props.moveInventoryUp}
              moveInventoryDown={this.props.moveInventoryDown}
              isPriceHidden={inventoryEntity.isPriceHidden}
            />
          ))}
        </div>
        <AddButton onClick={this.props.handleAddInventoryClick}>Add Upgrade</AddButton>
      </div>
    )
  }
}

const InventoryTypesListLabel = styled.div`
  display: block;
  color: ${props => props.theme.fontLabelColor};
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 15px;
  font-family: Roboto;
`

const mapStateToProps = state => ({
  categoryId: state.categories.edit.id,
  inventory: selectCategoryInventory(state),
  mediaUrl: state.main.mediaUrl,
  currencySymbol: state.main.currencySymbol,
})

const mapDispatchToProps = {
  copyInventory,
  editInventory,
  deleteInventory,
  moveInventoryUp,
  moveInventoryDown,
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryTypesList)
