// /* @flow */
import _ from 'lodash'
import * as actionTypes from '../actionTypes'
import { maxQuantityTypes, minQuantityTypes, chargeGratuityTypes, categoryFormFields, chargeServiceChargeTypes } from '../constants'
/* import type { Action } from '../flowTypes/actions' */
import genID from '../utils/genID'
import { swapArrayItems } from '../utils/reducerHelpers'
import { getTaxGroupItems } from './main'

/* export type CategoryEntity = {
  +id?: string,
  +tempId?: string,
  +categoryName: string,
  +isNameShown: boolean,
  +maxQuantityValue: number,
  +minQuantityValue: number,
  +maxQuantityType: $Keys<typeof maxQuantityTypes>,
  +minQuantityType: $Keys<typeof minQuantityTypes>,
  +inventory: Array<string>,
  +sortOrder: number,
  +isChargingTax: boolean,
  +isChargingGratuity: boolean,
  +gratuityChargeType: $Keys<typeof chargeGratuityTypes>,
  +gratuityPercentage: string,
  +taxGroupId: string,
  +isLoading: boolean,
  +formErrors: { [string]: boolean },
} */

/* export type CategoryEntities = {
  +[string]: CategoryEntity,
} */

/* export type CategoryState = {
  +edit: CategoryEntity,
  +entities: CategoryEntities,
  +viewOrder: Array<string>,
  +deleteCategoryId?: string,
} */

const initialState /* : CategoryState */ = {
  edit: {
    id: undefined,
    tempId: undefined,
    categoryName: '',
    isNameShown: false,
    maxQuantityValue: 1,
    minQuantityValue: 1,
    maxQuantityType: maxQuantityTypes.UNLIMITED,
    minQuantityType: minQuantityTypes.NONE_REQUIRED,
    isLoading: false,
    sortOrder: 0,
    isChargingTax: false,
    isChargingGratuity: false,
    requireGratuityCharge: false,
    gratuityChargeType: chargeGratuityTypes.DEFAULT_GRATUITY,
    gratuityPercentage: '0',
    taxGroupId: null,
    isChargingServiceCharge: false,
    serviceChargeType: chargeServiceChargeTypes.DEFAULT_SERVICE_CHARGE,
    serviceChargePercentage: '0',
    inventory: [],
    formErrors: {},
    maxQuantityAllowMultiples: false,
    advancedPDRUpgrades: false,
    advancedDescription: '',
    isFlexibleFeesIntegrationEnabled: false,
    pdrFees: [],
  },
  entities: {},
  viewOrder: [],
  deleteCategoryId: undefined,
}

export default (state /* : CategoryState */ = initialState, action /* : Action */) /* : CategoryState */ => {
  switch (action.type) {
    case actionTypes.ADD_CATEGORY:
      return {
        ...state,
        edit: {
          ...initialState.edit,
          temp: genID(),
          sortOrder: state.viewOrder.length,
        },
      }
    case actionTypes.CHANGE_CATEGORY_FIELD:
    case actionTypes.TOGGLE_CATEGORY_FIELD:
    case actionTypes.CHANGE_TAX_GROUP_ID:
      return { ...state, edit: edit(state.edit, action) }
    case actionTypes.VALIDATE_CATEGORY:
      return { ...state, edit: edit(state.edit, action) }
    case actionTypes.EDIT_CATEGORY:
      return {
        ...state,
        edit: {
          ...state.entities[action.categoryId],
          formErrors: {},
        },
      }
    case actionTypes.CATEGORY_POST_SUCCESS: {
      const newViewOrder = state.viewOrder.slice()
      newViewOrder.push(action.categoryEntity.id)
      return {
        ...state,
        viewOrder: newViewOrder,
        edit: edit(state.edit, action),
        entities: entities(state.entities, action),
      }
    }
    case actionTypes.DATA_GET_SUCCESS:
      return {
        ...state,
        entities: entities(state.entities, action),
        viewOrder: action.payload.categoriesViewOrder,
      }
    case actionTypes.CATEGORY_DELETE_FAILURE:
    case actionTypes.CATEGORY_DELETE_ATTEMPT:
    case actionTypes.CATEGORY_PUT_SUCCESS:
    case actionTypes.REORDER_CATEGORIES_SUCCESS:
      return {
        ...state,
        entities: entities(state.entities, action),
      }
    case actionTypes.CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        viewOrder: _.pull(state.viewOrder.slice(), action.categoryId),
        entities: entities(state.entities, action),
      }
    case actionTypes.CATEGORY_COPY_SUCCESS:
      return {
        ...state,
        edit: edit(state.edit, action),
        entities: entities(state.entities, action),
        viewOrder: state.viewOrder.concat(action.payload.category.id),
      }
    case actionTypes.INVENTORY_POST_SUCCESS:
    case actionTypes.INVENTORY_DELETE_SUCCESS:
      return {
        ...state,
        edit: edit(state.edit, action),
        entities: entities(state.entities, action),
      }
    case actionTypes.CLICK_DELETE_CATEGORY:
      return {
        ...state,
        deleteCategoryId: action.categoryId,
      }
    case actionTypes.CANCEL_DELETE_CATEGORY:
      return {
        ...state,
        deleteCategoryId: undefined,
      }
    case actionTypes.MOVE_CATEGORY_UP: {
      const { categoryId } = action
      const categoryIndex = _.findIndex(state.viewOrder, id => id === categoryId)
      if (categoryIndex === state.viewOrder.length - 1) {
        return { ...state }
      }
      return {
        ...state,
        viewOrder: swapArrayItems(state.viewOrder, categoryIndex, categoryIndex + 1),
      }
    }
    case actionTypes.MOVE_CATEGORY_DOWN: {
      const { categoryId } = action
      const categoryIndex = _.findIndex(state.viewOrder, id => id === categoryId)
      if (categoryIndex === 0) {
        return { ...state }
      }
      return {
        ...state,
        viewOrder: swapArrayItems(state.viewOrder, categoryIndex, categoryIndex - 1),
      }
    }
    case actionTypes.MOVE_INVENTORY_UP: {
      const categoryId = state.edit.id
      if (!categoryId) {
        return { ...state }
      }
      const { inventoryId } = action
      const inventoryIndex = _.findIndex(state.edit.inventory, id => id === inventoryId)
      if (inventoryIndex === state.edit.inventory.length - 1) {
        return { ...state }
      }
      const newInventoryViewOrder = swapArrayItems(state.edit.inventory, inventoryIndex, inventoryIndex + 1)
      return {
        ...state,
        edit: {
          ...state.edit,
          inventory: newInventoryViewOrder,
        },
        entities: {
          ...state.entities,
          [categoryId]: {
            ...state.entities[categoryId],
            inventory: newInventoryViewOrder,
          },
        },
      }
    }
    case actionTypes.MOVE_INVENTORY_DOWN: {
      const categoryId = state.edit.id
      if (!categoryId) {
        return { ...state }
      }
      const { inventoryId } = action
      const inventoryIndex = _.findIndex(state.edit.inventory, id => id === inventoryId)
      if (inventoryIndex === 0) {
        return { ...state }
      }
      const newInventoryViewOrder = swapArrayItems(state.edit.inventory, inventoryIndex, inventoryIndex - 1)
      return {
        ...state,
        edit: {
          ...state.edit,
          inventory: newInventoryViewOrder,
        },
        entities: {
          ...state.entities,
          [categoryId]: {
            ...state.entities[categoryId],
            inventory: newInventoryViewOrder,
          },
        },
      }
    }
    default:
      return state
  }
}

const edit = (state /* : CategoryEntity */, action /* : Action */) => {
  switch (action.type) {
    case actionTypes.CHANGE_CATEGORY_FIELD: {
      const updateFields /* : { [string]: mixed } */ = { [action.field]: action.value }
      // logic for settings IS_NAME_SHOWN when min quantity is required
      if (action.field === categoryFormFields.MIN_QUANTITY_TYPE) {
        if (action.value !== minQuantityTypes.NONE_REQUIRED) {
          updateFields[categoryFormFields.IS_NAME_SHOWN] = true
        }
      }

      const newFormErrors = { ...state.formErrors }
      delete newFormErrors[action.field]

      return {
        ...state,
        ...updateFields,
        formErrors: newFormErrors,
      }
    }
    case actionTypes.TOGGLE_CATEGORY_FIELD:
      const updateToggleCategoryFields = {
        [action.field]: !state[action.field],
      }
      if (action.field === 'isChargingTax') {
        if (state[action.field]) {
          Object.assign(updateToggleCategoryFields, {
            taxGroupSelected: [],
            taxGroupId: null,
          })
        } else {
          const { taxGroupsOptions } = getTaxGroupItems()
          const firstTaxGroup = taxGroupsOptions[0]
          Object.assign(updateToggleCategoryFields, {
            taxGroupSelected: [firstTaxGroup],
            taxGroupId: firstTaxGroup.value,
          })
        }
      } else if (action.field === 'isChargingGratuity') {
        if (state[action.field]) {
          Object.assign(updateToggleCategoryFields, {
            requireGratuityCharge: false,
          })
        }
      } else if (action.field === 'isChargingServiceCharge') {
        if (state[action.field]) {
          Object.assign(updateToggleCategoryFields, {
            serviceChargeType: chargeServiceChargeTypes.DEFAULT_SERVICE_CHARGE,
            serviceChargePercentage: '0',
          })
        }
      }
      return {
        ...state,
        ...updateToggleCategoryFields,
      }
    case actionTypes.CHANGE_TAX_GROUP_ID:
      const updateTaxGroupFields = {
        taxGroupSelected: action.value,
        taxGroupId: action.value[0].value,
      }
      return {
        ...state,
        ...updateTaxGroupFields,
      }
    case actionTypes.VALIDATE_CATEGORY:
      return { ...state, formErrors: action.formErrors }
    case actionTypes.CATEGORY_POST_SUCCESS:
      return { ...state, id: action.categoryEntity.id }
    case actionTypes.INVENTORY_POST_SUCCESS:
      return {
        ...state,
        inventory: state.inventory.slice().concat(action.inventoryEntity.id),
      }
    case actionTypes.INVENTORY_DELETE_SUCCESS: {
      return {
        ...state,
        inventory: _.pull(state.inventory.slice(), action.inventoryId),
      }
    }
    case actionTypes.CATEGORY_COPY_SUCCESS:
      return {
        ...state,
        ...action.payload.category,
        formErrors: {},
      }
    default:
      return state
  }
}

const entities = (state /* : CategoryEntities */, action /* : Action */) /* : CategoryEntities */ => {
  switch (action.type) {
    case actionTypes.DATA_GET_SUCCESS:
      return { ...state, ...action.payload.categories }
    case actionTypes.CATEGORY_DELETE_SUCCESS: {
      const newState = { ...state }
      delete newState[action.categoryId]
      return newState
    }
    case actionTypes.CATEGORY_DELETE_ATTEMPT:
      return {
        ...state,
        [action.categoryId]: {
          ...state[action.categoryId],
          isLoading: true,
        },
      }
    case actionTypes.CATEGORY_DELETE_FAILURE: {
      return {
        ...state,
        [action.categoryId]: {
          ...state[action.categoryId],
          isLoading: false,
        },
      }
    }
    case actionTypes.CATEGORY_POST_SUCCESS:
      return {
        ...state,
        [action.categoryEntity.id]: action.categoryEntity,
      }
    case actionTypes.CATEGORY_PUT_SUCCESS: {
      const inventory = state[action.categoryEntity.id].inventory.slice()
      return {
        ...state,
        [action.categoryEntity.id]: {
          ...action.categoryEntity,
          inventory,
        },
      }
    }
    case actionTypes.INVENTORY_POST_SUCCESS: {
      const { categoryId } = action.inventoryEntity
      return {
        ...state,
        [categoryId]: {
          ...state[categoryId],
          inventory: state[categoryId].inventory.slice().concat(action.inventoryEntity.id),
        },
      }
    }
    case actionTypes.INVENTORY_DELETE_SUCCESS: {
      return {
        ...state,
        [action.categoryId]: {
          ...state[action.categoryId],
          inventory: _.pull(state[action.categoryId].inventory.slice(), action.inventoryId),
        },
      }
    }
    case actionTypes.CATEGORY_COPY_SUCCESS:
      return {
        ...state,
        [action.payload.category.id]: action.payload.category,
      }
    case actionTypes.REORDER_CATEGORIES_SUCCESS: {
      const { sortOrderMap } = action
      return _.mapValues({ ...state }, (entity, entityId) =>
        sortOrderMap[entityId] ? _.assign(entity, { sortOrder: sortOrderMap[entityId].sortOrder }) : entity
      )
    }
    default:
      return state
  }
}
