// /* @flow */
import { srGet } from '@sevenrooms/core/api'
import * as actionTypes from '../actionTypes'
import { transformDataFromGetApi } from '../utils/transformData'
/* import type { Dispatch, GetState } from '../flowTypes/redux' */
/* import type { Action } from '../flowTypes/actions' */

const API_NAMESPACE = '/api-yoa'

export const getFees = () => async venueId  => {
  const getResponse = await srGet(`${API_NAMESPACE}/${venueId}/fees`, {})
  return getResponse.status === 200 ? getResponse.data : {}
}

export const getData = () => async (dispatch /* : Dispatch */, getState /* : GetState */) => {
  const state = getState()
  dispatch(dataGetAttempt())
  const getResponse = await srGet(`${API_NAMESPACE}/venue/upsells/list`, {
    venue: state.main.venueId,
  })

  if (getResponse.status === 200) {
    const getModifiers = await srGet(`${API_NAMESPACE}/venue/upsells/modifier`, {
      venue: state.main.venueId,
    })
    const getModifiersGroups = await srGet(`${API_NAMESPACE}/venue/upsells/modifier-group`, {
      venue: state.main.venueId,
    })
    if (getModifiers.status === 200 && getModifiersGroups.status === 200) {
      state.main.modifiers = getModifiers.data.modifiers
      state.main.modifiersGroups = getModifiersGroups.data.modifier_groups
    } else {
      state.main.modifiers = getModifiers.errorMsg
    }
  }
  return getResponse.status === 200
    ? dispatch(dataGetSuccess(transformDataFromGetApi(getResponse.data, state.main)))
    : dispatch(dataGetFailure())
}

// HTTP Action creators
const dataGetAttempt = () /* : Action */ => ({ type: actionTypes.DATA_GET_ATTEMPT })
const dataGetSuccess = (payload) /* : Action */ => ({
  type: actionTypes.DATA_GET_SUCCESS,
  payload,
})
const dataGetFailure = () /* : Action */ => ({ type: actionTypes.DATA_GET_FAILURE })
