// /* @flow */
import * as React from 'react'
import styled from 'styled-components'

/* type Props = {
  imgSrc: string,
  tooltip: string,
  onClick?: () => mixed,
  customButton: Array<string | (() => mixed)>,
} */

const UtilButton = (props /* : Props */) => (
  <Button onClick={props.onClick} imgSrc={props.imgSrc} data-tip={props.tooltip} customButton={props.customButton} />
)

UtilButton.defaultProps = {
  customButton: [],
}

const Button = styled.button`
  display: inline-block;
  height: 37px;
  width: 33px;
  margin-left: 5px;
  outline: none;
  cursor: pointer;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  background-color: rgb(241, 241, 241);
  background-size: 15px 15px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.imgSrc})`};
  :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 1;
  }
  ${props => props.customButton};
`

export default UtilButton
