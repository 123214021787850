// /* @flow */
import * as actionTypes from '../actionTypes'
/* import type {
  SimpleAction,
  CategoryAction,
  ChangeFieldCategoryAction,
  ToggleFieldCategoryAction,
  ClickSaveCategoryAction,
} from '../flowTypes/actions' */

const cancelCategory = () /* : SimpleAction */ => ({
  type: actionTypes.CANCEL_CATEGORY,
})
const addCategory = () /* : SimpleAction */ => ({ type: actionTypes.ADD_CATEGORY })

const editCategory = (categoryId /* : string */) /* : CategoryAction */ => ({
  type: actionTypes.EDIT_CATEGORY,
  categoryId,
})

const validateCategory = (
  formErrors /* : {
  [string]: boolean,
} */
) /* : ClickSaveCategoryAction */ => ({
  type: actionTypes.VALIDATE_CATEGORY,
  formErrors,
})

const changeCategoryField = (value /* : string | number */, field /* : string */) /* : ChangeFieldCategoryAction */ => ({
  type: actionTypes.CHANGE_CATEGORY_FIELD,
  field,
  value,
})

const toggleCategoryField = (field /* : string */) /* : ToggleFieldCategoryAction */ => ({
  type: actionTypes.TOGGLE_CATEGORY_FIELD,
  field,
})

const clickDeleteCategory = (categoryId /* : string */) /* : CategoryAction */ => ({
  type: actionTypes.CLICK_DELETE_CATEGORY,
  categoryId,
})

const cancelDeleteCategory = () /* : SimpleAction */ => ({
  type: actionTypes.CANCEL_DELETE_CATEGORY,
})

const changeCategoryTaxGroupId = (value /* : string */) /* : ChangeFieldCategoryAction */ => ({
  type: actionTypes.CHANGE_TAX_GROUP_ID,
  value,
})

export default {
  addCategory,
  cancelCategory,
  editCategory,
  validateCategory,
  changeCategoryField,
  toggleCategoryField,
  clickDeleteCategory,
  cancelDeleteCategory,
  changeCategoryTaxGroupId,
}
