// /* @flow */
import * as React from 'react'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components'
import CategoryMultiSelectDropdown from './CategoryMultiSelectDropdown'
import CategoryMultiSelectInput from './CategoryMultiSelectInput'

/* type Props = {
  options: { [string]: { label: string, isSelected: boolean } },
  onSelect: ({ [string]: boolean }) => void,
  width: number,
  dataTest?: string,
} */

/* type State = {
  displayDropdown: boolean,
} */

class CategoryMultiSelect extends React.Component /* <Props, State> */ {
  state = { displayDropdown: false }

  handleClickOutside = () => {
    this.setState({ displayDropdown: false })
  }

  inputClickHandler = () => {
    this.setState({ displayDropdown: !this.state.displayDropdown })
  }

  optionClickHandler = optionKey => {
    this.props.onSelect({
      ...this.props.options,
      [optionKey]: {
        ...this.props.options[optionKey],
        isSelected: !this.props.options[optionKey].isSelected,
      },
    })
  }

  render() {
    return (
      <MultiSelectWrapper width={this.props.width} data-test={this.props.dataTest}>
        <CategoryMultiSelectInput onClick={this.inputClickHandler} options={this.props.options} />
        {this.state.displayDropdown && (
          <CategoryMultiSelectDropdown options={this.props.options} optionClickHandler={this.optionClickHandler} />
        )}
      </MultiSelectWrapper>
    )
  }
}

CategoryMultiSelect.defaultProps = {
  width: 275,
}

const MultiSelectWrapper = styled.div`
  position: relative;
  width: ${props => props.width}px;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
`

export default onClickOutside(CategoryMultiSelect)
