// /* @flow */
import * as React from 'react'
import { connect } from 'react-redux'
import { css } from 'styled-components'
import { validateNotEmpty } from '../../../../common/Validators'
import TextInput from '../../../../component-lib/Generic/TextInputs/TextInput'
import categoryActions from '../actions/categories'
import AdditionalOptions from '../components/AdditionalOptions'
import MaxQuantityRadioList from '../components/MaxQuantityRadioList'
import MinQuantityRadioList from '../components/MinQuantityRadioList'
import { categoryFormFields } from '../constants'
import { selectIsNameShownDisabled } from '../selectors'
import CheckboxLine from 'svr/component-lib/Generic/Checkbox/CheckboxLine'
import { useVenueSettingsContext } from '@sevenrooms/mgr-core'
import AdvancedUpgradeCheck from '../components/AdvancedUpgradeCheck'
import AdvancedUpgradeDescription from '../components/AdvancedUpgradeDescription'

const EditCategoryForm = (props /* : Props */) => {
  const { venueSettings } = useVenueSettingsContext()
  return (
    <div>
      <TextInput
        field={categoryFormFields.CATEGORY_NAME}
        value={props.categoryName}
        label="Category name"
        onChange={props.changeCategoryField}
        isValid={!props.formErrors[categoryFormFields.CATEGORY_NAME]}
        validator={validateNotEmpty}
        // TODO: remove important clauses when css class of site no longer overrules styled-components
        customInput={css`
          color: ${props => props.theme.fontInputColor} !important;
          width: 100% !important;
          padding: 0 4px !important;
          border-radius: 5px !important;
          border-width: 2px !important;
          font-size: 18px !important;
          border-style: solid !important;
          border-color: rgba(236, 236, 236, 1) !important;
          background-color: rgba(255, 255, 255, 1) !important;
        `}
        customInputLabel={css`
          color: ${props => props.theme.fontLabelColor};
        `}
        customInputWrapper={css`
          width: 60%;
          max-width: 600px;
          margin-bottom: 10px;
        `}
        ref={input => {
          // eslint-disable-next-line no-param-reassign
          props.categoryInputs[categoryFormFields.CATEGORY_NAME] = input
        }}
      />
      {venueSettings?.advanced_pdr_upgrades && (
        <AdvancedUpgradeCheck toggleCategoryField={props.toggleCategoryField} advancedPDRUpgrades={props.advancedPDRUpgrades} />
      )}
      {venueSettings?.advanced_pdr_upgrades && (
        <AdvancedUpgradeDescription
          changeCategoryField={props.changeCategoryField}
          content={props.advancedDescription}
          advancedPDRUpgrades={props.advancedPDRUpgrades}
        />
      )}
      <MinQuantityRadioList
        minQuantityTypeField={categoryFormFields.MIN_QUANTITY_TYPE}
        minQuantityValueField={categoryFormFields.MIN_QUANTITY_VALUE}
        minQuantityType={props.minQuantityType}
        minQuantityValue={props.minQuantityValue}
        changeCategoryField={props.changeCategoryField}
      />
      <MaxQuantityRadioList
        maxQuantityTypeField={categoryFormFields.MAX_QUANTITY_TYPE}
        maxQuantityValueField={categoryFormFields.MAX_QUANTITY_VALUE}
        maxQuantityType={props.maxQuantityType}
        maxQuantityValue={props.maxQuantityValue}
        maxQuantityAllowMultiples={props.maxQuantityAllowMultiples}
        changeCategoryField={props.changeCategoryField}
        toggleCategoryField={props.toggleCategoryField}
        advancedPDRUpgrades={props.advancedPDRUpgrades}
      />
      <AdditionalOptions
        isNameShown={props.isNameShown}
        isChargingTax={props.isChargingTax}
        isChargingGratuity={props.isChargingGratuity}
        requireGratuityCharge={props.requireGratuityCharge}
        gratuityPercentage={props.gratuityPercentage}
        gratuityChargeType={props.gratuityChargeType}
        defaultServiceChargePercentage={props.defaultServiceChargePercentage}
        defaultGratuityPercentage={props.defaultGratuityPercentage}
        taxGroups={props.taxGroups}
        taxGroupsOptions={props.taxGroupsOptions}
        taxGroupSelected={props.taxGroupSelected}
        isNameShownDisabled={props.isNameShownDisabled}
        toggleCategoryField={props.toggleCategoryField}
        changeCategoryField={props.changeCategoryField}
        changeCategoryTaxGroupId={props.changeCategoryTaxGroupId}
        isChargingServiceCharge={props.isChargingServiceCharge}
        serviceChargeType={props.serviceChargeType}
        serviceChargePercentage={props.serviceChargePercentage}
        pdrFees={props.pdrFees}
        venueId={props.venueId}
        advancedPDRUpgrades={props.advancedPDRUpgrades}
        isFlexibleFeesIntegrationEnabled={venueSettings?.is_flexible_fees_integration_enabled}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  categoryName: state.categories.edit.categoryName,
  isNameShown: state.categories.edit.isNameShown,
  isNameShownDisabled: selectIsNameShownDisabled(state),
  isChargingTax: state.categories.edit.isChargingTax,
  isChargingGratuity: state.categories.edit.isChargingGratuity,
  requireGratuityCharge: state.categories.edit.requireGratuityCharge,
  maxQuantityType: state.categories.edit.maxQuantityType,
  maxQuantityValue: state.categories.edit.maxQuantityValue,
  minQuantityType: state.categories.edit.minQuantityType,
  minQuantityValue: state.categories.edit.minQuantityValue,
  gratuityPercentage: state.categories.edit.gratuityPercentage,
  gratuityChargeType: state.categories.edit.gratuityChargeType,
  defaultServiceChargePercentage: state.main.defaultServiceChargePercentage,
  defaultGratuityPercentage: state.main.defaultGratuityPercentage,
  taxGroups: state.main.taxGroups,
  taxGroupsOptions: state.main.taxGroupsOptions,
  taxGroupSelected: state.categories.edit.taxGroupSelected,
  formErrors: state.categories.edit.formErrors,
  isChargingServiceCharge: state.categories.edit.isChargingServiceCharge,
  serviceChargeType: state.categories.edit.serviceChargeType,
  serviceChargePercentage: state.categories.edit.serviceChargePercentage,
  maxQuantityAllowMultiples: state.categories.edit.maxQuantityAllowMultiples,
  advancedPDRUpgrades: state.categories.edit.advancedPDRUpgrades,
  advancedDescription: state.categories.edit.advancedDescription,
  isFlexibleFeesIntegrationEnabled: state.categories.edit.isFlexibleFeesIntegrationEnabled,
  pdrFees: state.categories.edit.pdrFees,
  venueId: state.main.venueId,
})

const mapDispatchToProps = {
  changeCategoryField: categoryActions.changeCategoryField,
  toggleCategoryField: categoryActions.toggleCategoryField,
  changeCategoryTaxGroupId: categoryActions.changeCategoryTaxGroupId,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCategoryForm)
